import { useState } from 'react'
import { Control, Icon, Input } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressItem, AddressList, Container, ItemIcon } from './styles'
import GeocodeService from '../../../services/geocoder'
import { GoogleMapsApi, VacancyService } from '../../../services/api-zad'
import { faMapPin } from '@fortawesome/free-solid-svg-icons'

export const SearchParkingPlace = ({ setAddress, setLatitude, setLongitude, setReferences, local=false }) => {
  const [searchAddress, setSearchAddress] = useState('')
  const [addresses, setAddresses] = useState([])
  const [vacancies, setVacancies] = useState([])

  const selectAddress = async (address) => {
    const response = await GeocodeService.fromAddress(address)
    const { lat, lng } = response.results[0].geometry.location
    const { formatted_address } = response.results[0]
    setLatitude(lat)
    setLongitude(lng)
    setAddresses([])
    setSearchAddress('')

    if (local) {
      setAddress(address)
      setReferences(
        vacancies.find(vacancy => vacancy.fullStreet === address).references
      )
    } else {
      setAddress(formatted_address)
    }
  }

  const fetchAddresses = async (value) => {
    if (value.length >= 3) {
      if (local) {
        const params = new URLSearchParams({ address: value, disabled: false })
        const response = await VacancyService.index(params)
        setVacancies(response.data.data)
        setAddresses(response.data.data.map(vacancy => vacancy.fullStreet))
      } else {
        const response = await GoogleMapsApi.fromAddress(value)
        setAddresses(response.data.data)
      }
    } else {
      if (addresses.length) setAddresses([])
    }
  }

  return (
    <Container>
      <Control iconLeft>
        <Input 
          id='search'
          placeholder="Pesquise o local do estacionamento"
          autoComplete='off'
          value={searchAddress}
          onChange={(event) => {
            fetchAddresses(event.target.value)
            setSearchAddress(event.target.value)
          }}
        />
        <Icon align="left">
          <FontAwesomeIcon icon={faMapPin} />
        </Icon>
        <AddressList addresses={searchAddress}>
          {
            addresses.length ? (
              addresses.map((address, i) => (
                <AddressItem key={i} onClick={index => selectAddress(address)}>
                  <ItemIcon /> { address }
                </AddressItem>
              ))
            ) : (
              <AddressItem>
                Nenhum endereço encontrado.
              </AddressItem>
            )
          }
        </AddressList>
      </Control>
    </Container>
  )
}
