import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Menu } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faListUl } from '@fortawesome/free-solid-svg-icons'
import appIconImage from '../../../assets/logo_zad.png'
import burgerIconImage from '../../../assets/burger.svg'
import { MenuItems } from '../menuItems'
import './styles.scss'

export const Sidebar = ({ profile }) => {
  const sidebarRef = useRef(null)
  const sidebarMainRef = useRef(null)

  useEffect(() => {
    document.addEventListener('click', hideSidebar)
    return () => document.removeEventListener('click', hideSidebar)
  },[])

  const showSidebar = () => {
    sidebarRef.current.classList.add('gb_za')
  }

  const hideSidebar = (event) => {
    if (sidebarMainRef.current !== null && event.target instanceof Element && !sidebarMainRef.current.contains(event.target)) {
      sidebarRef.current.classList.remove('gb_za')
    }
  }

  return (
    <div className='is-relative' ref={sidebarMainRef}>
      <div className='sidebar-trigger'>
        <Icon size='large' className='sidebar-trigger is-clickable is-white gb_Fc' onClick={showSidebar}>
          <img src={burgerIconImage} alt="burgerIcon" style={{height:'35px'}} />
        </Icon>
      </div>
      <div 
        className={'sidebar dark is-flex is-flex-direction-column is-clipped gb_Mc gb_Sc'} 
        ref={sidebarRef}
        role='navigation'
      >
        <div className='logo'>
          <div>
            <img src={appIconImage} alt="logoImage" />
          </div>
        </div>
        <div className='is-flex p-4' role='menu' style={{overflowY:'auto'}}>
          <Menu>
            {
              MenuItems[profile]?.map(menu => (
                <div key={Math.random()} className='mb-3'>
                  <Menu.Label>{ menu.title.toUpperCase() }</Menu.Label>
                  <Menu.List>
                    {
                      menu.items.map((item, idx) => (
                        <li key={idx}>
                          <Link
                            to={item.external ? { pathname: item.to } : item.to}
                            target={item.external ? '_blank' : '_self'}
                            className='is-flex'
                          >
                            <div>
                              <FontAwesomeIcon icon={ item.label === 'Home' ? faHome : faListUl} className='mr-2' />
                            </div>
                            <div>
                              { item.label }
                            </div>
                          </Link>
                        </li>
                      ))
                    }
                  </Menu.List>
                </div>
              ))
            }
          </Menu>
        </div>
      </div>
    </div>
  )
}
