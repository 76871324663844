import { useState } from 'react'
import { Box, Control, Field, Icon, Input, Title } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { convertResponseBlobToJson, handleDownload } from '../../../commons/utils'
import { DataExtractionService } from '../../../services/api-zad'
import moment from 'moment'

export const DataExtractionList = () => {
  const lastMonth = moment().subtract(1, 'month')

  const [load, setLoad] = useState(null)
  const [startDate, setStartDate] = useState(lastMonth.startOf('month').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(lastMonth.endOf('month').format('YYYY-MM-DD'))

  // console.log('DataExtractionList', 'Rendered')
  
  const downloadFileTransactions = async () => {
    if (window.confirm('Confirmar o download do arquivo?')) {
      setLoad(1)
      try {
        const response = await DataExtractionService.downloadFileTransactions({ startDate, endDate })
        handleDownload(response, 'transactions.xlsx')
      } catch (e) {
        if (e.response) {
          const data = await convertResponseBlobToJson(e.response)
          toast.error(data.message)
        } else
          toast.error(e.message)
      }
      setLoad(null)
    }
  }

  return (
    <Box>
      <div className='is-flex is-align-items-center is-justify-content-space-between'>
        <Field kind="group" multiline className='is-align-items-center mb-0'>
          <Title subtitle size={5} className="mr-2 mb-0">Período:</Title>
          <Control className="mb-0">
            <Input 
              type='date' 
              size={'small'} 
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
            />
          </Control>
          <Control className="mb-0">
            <Input 
              type='date' 
              size={'small'} 
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
            />
          </Control>
        </Field>
      </div>
      <hr className='hr my-3' />
      <div className="is-flex is-align-items-center">
        <Icon className='is-clickable ml-2' title='Download Planilha' onClick={downloadFileTransactions}>
          {
            load
              ? <FontAwesomeIcon icon={faSpinner} className='is-size-5' pulse />
              : <FontAwesomeIcon icon={faFileExcel} className='is-size-5' />
          }          
        </Icon>
        <span className="ml-4">Transações de Compra de CAD</span>
      </div>
      <hr className='hr my-3' />
    </Box>
  )
}
