import { FooterHome } from "../../components/FooterHome";
import { HeaderHome } from "../../components/HeaderHome";
import { Container, Content, Cover } from "../../styles/styles";

export default function LayoutHomePage({ children }) {
  const city = process.env.REACT_APP_CITY||''
  return (
    <Container>
      <HeaderHome />
      <Content id="topo">
        <Cover>
          <h1>
            Zona Azul <span>Gerência</span> { city }
          </h1>
          <p>A solução ideal para o estacionamento rotativo da sua cidade</p>
        </Cover>
        {children}
      </Content>
      <FooterHome />
    </Container>
  );
}
