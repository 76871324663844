import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input,
    & + select {
      margin-top: 1rem;
    }
  }

  button[type='submit'],
  button {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--green);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    button {
      margin-top: 0 !important;
      background: var(--green) !important;
    }

    span {
      margin-top: 0 !important;
    }

    /* @media (max-width: 720px) { */
    flex-direction: column;

    .red {
      max-width: 100%;
    }
    /* } */
  }

  .timer {
    width: 100px;
    background: var(--green);
    border-radius: 0.25rem;
    text-align: center;
    color: #fff;
    margin-left: 5px;
  }

  .irregular {
    background: var(--red);
  }

  .red {
    background: var(--red) !important;
    max-width: calc(50% - 0.5rem);
  }
`;

export const Grid = styled.span`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  input + input {
    margin-top: 0rem;
  }

  span {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
  }
`;
