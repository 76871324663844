import { useState } from "react"
import { Button, Card, Column, Label } from "rbx"
import { toast } from "react-toastify"
import { CardHeader } from "../../shared/CardHeader"
import Controls from "../../shared/Controls/Controls"
import pixImg from '../../../assets/pix.svg';
import creditCardImg from '../../../assets/creditCard.svg';
import './styles.scss'
import UserService from "../../../services/user"
import PurchaseSummary from '../../shared/PurchaseSummary';

export const AppSellCad = ({ goToMenu, userData }) => {
  const [quantity, setQuantity] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [stateButton, setStateButton] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {     
      if (validate()) {
        setStateButton('loading')
        const body = {
          cpfCnpj: userData.cpf_cnpj,
          quantity,
          paymentMethod,
        };
        const response = await UserService.sellCad(body)
        await UserService.reload();
        toast.success(response.data.message)
        goToMenu()
      }
    } catch (e) {
      if (e.response.data)
        toast.error(e.response.data.message)
      else
        toast.error(`${e.response.status}: ${e.response.data.errors}`)
    }
    setStateButton('')
  }

  const validate = () => {
    if (!userData.cpf_cnpj) {
      toast.error('Informe o CPF/CNPJ do usuário!'); return false
    }
    
    if (!quantity || +quantity < 1) {
      toast.error('Informe a quantidade de CAD!'); return false
    }

    if (!paymentMethod) {
      toast.error('Informe a forma de pagamento!'); return false
    }
    return true
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader handleClose={goToMenu}>Vender CAD</CardHeader>
        <Card.Content>
          <Column.Group>
            <Column>
              <Column.Group className='is-flex-direction-column'>
                <Column>
                  <Controls.TextField 
                    label="Quantidade"
                    name="quantidade"
                    type="number"
                    min={1}
                    max={999}
                    placeholder='Quantidade'
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    autoFocus
                    required
                  />
                </Column>
                <Column>
                  <Label>Forma de pagamento</Label>
                  <Column.Group breakpoint="mobile">
                    <Column>
                      <Button 
                        className={ paymentMethod==='pix' ? 'button-payment active' : 'button-payment' }
                        type="button" 
                        color="custom-purple" 
                        outlined
                        fullwidth
                        onClick={() => setPaymentMethod('pix')}
                      >
                        <img src={pixImg} className="pix" alt="Pix" /> 
                        Pix
                      </Button>
                    </Column>
                    <Column>
                      <Button 
                        className={ paymentMethod==='debit_card' ? 'button-payment active' : 'button-payment' }
                        type="button" 
                        color="custom-purple" 
                        outlined
                        fullwidth
                        onClick={() => setPaymentMethod('debit_card')}
                      >
                        <img src={creditCardImg} alt="Débito" />
                        Débito
                      </Button>
                    </Column>
                    <Column>
                      <Button 
                        className={ paymentMethod==='credit_card' ? 'button-payment active' : 'button-payment' }
                        type="button" 
                        color="custom-purple" 
                        outlined
                        fullwidth
                        onClick={() => setPaymentMethod('credit_card')}
                      >
                        <img src={creditCardImg} alt="Crétido" />
                        Crédito
                      </Button>
                    </Column>
                  </Column.Group>
                </Column>
              </Column.Group>
            </Column>
            <Column>
            <PurchaseSummary quantity={quantity} discount={userData.discount||0} />
            </Column>
          </Column.Group>
          <hr className="hr" />
          <Controls.ActionButtons
            linkName="Fechar" 
            handleClickBack={goToMenu} 
            buttonState={stateButton} 
            buttonName='Vender'
          />
        </Card.Content>
      </Card>
    </form>
  )
}