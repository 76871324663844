import { useState } from 'react';
import Modal from 'react-modal';
import { Container, InfoContainer } from './styles';
import closeImg from '../../../../assets/close.svg';
import { toast } from 'react-toastify';
import IrregularVehicleService from '../../../../services/irregular-vehicle';

export function ConfirmedIrregularityDetail({ isOpen, onRequestClose, detailsData, refreshList }) {
  const profile = localStorage.getItem('profile')
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true)
    try {
      const response = await IrregularVehicleService.fineIrregularVehicle(detailsData.id)
      toast.success(response.data.message)
      refreshList()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setTimeout(() => {
        setLoading(false)
        onRequestClose()
      }, 3000);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container>
        <h3>Irregularidade Registrada - Monitor</h3>

        <p>
          Local do estacionamento
          <br />
          <span>{detailsData.local}</span>
          <br />
          Ref: <span>{detailsData.reference || '-'}</span>
        </p>

        <InfoContainer>
          <p>
            Placa Identificada <br />
            <span>{detailsData.licensePlate}</span>
          </p>
          <p>
            Data
            <br />
            <span>{detailsData.date}</span>
          </p>
          <p>
            Início - Fim
            <br />
            <span>{detailsData.startDate}</span> -
            <span> {detailsData.endDate}</span>
          </p>
        </InfoContainer>

        <hr/>
        <h3>Confirmação do Fiscal</h3>

        <p>
          Local Confirmado <br />
          <span>{detailsData.confirmedLocal}</span>
        </p>
        <InfoContainer>
          <p>
            Placa Confirmada <br />
            <span>{detailsData.confirmedLicensePlate}</span>
          </p>
          <p>
            Infração <br />
            <span>{detailsData.infractionCode}</span>
          </p>
          <p>
            Data e hora <br />
            <span>{detailsData.confirmedAt}</span>
          </p>
        </InfoContainer>

        <p>
          Observações <br />
          <span>
            {detailsData.note || '-'}
          </span>
        </p>

        {
          profile==='agent' && (
            <p style={{textAlign:'center'}}>
              {loading ? (
                <button className="secondButton" disabled>
                  Confirmando ...
                </button>
              ) : (
                <button className="secondButton" onClick={handleClick}>
                  Confirmar Autuação
                </button>
              )}
            </p>
          )
        }
      </Container>
    </Modal>
  )
}
