import { useState } from 'react';
import { API_URL } from '../../../services/api';
import Loading from '../../../helpers/Loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { TitleButtons } from '../../TitleButtons'
import { Container, ContainerForm, InputGrid } from '../styles';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { RedirectTo } from '../../../utils/redirectTo';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import { toNumber } from '../../../helpers/toCurrency';
import { Column } from 'rbx';

export function Parking() {
  const [parkingData, setParkingData] = useState({});
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState('')

  async function fetchParking() {
    setLoading(true);
    const res = await fetch(
      `${API_URL}/adm/report?startDate=${startDate}&endDate=${endDate}`,
      {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token'),
        },
      },
    );
    const response = await res.json();
    const body = response.data;

    // console.log(response);
    // console.log(body);

    if (body === null) {
      toast.error('Nenhum estacionamento localizado com esses parâmetros.');
      setLoading(false);
      return;
    }
    setParkingData(body);
    setLoading(false);
  }

  if (redirectTo)
    return <Redirect to={{ pathname: redirectTo }} />

  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'area',
      label: 'Área',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'startDate',
      label: 'Data início',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'endDate',
      label: 'Data fim',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'parkedTime',
      label: 'Tempo estacionado',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'amountCad',
      label: 'CAD ativados',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'cadRefunded',
      label: 'CAD restituídos',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'cadUsed',
      label: 'CAD utilizados',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'addressName',
      label: 'Local do estacionamento',
      options: { filter: false, sort: false, display: true },
    },
    {
      name: 'licensePlate',
      label: 'Placa do veículo',
      options: { filter: true, sort: true, display: true },
    },
  ];

  const options = {
    responsive: 'standard',
    draggableColumns: { enabled: true },
    selectableRows: 'none',
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: 'false',
    filter: 'false',
    download: 'true',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'Estacionamentos.csv',
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
    customTableBodyFooterRender: (opts) => {
      // console.dir('opts: ', opts)
      const cadSpent = opts.data.reduce((t, item) => (t + item.data[4]), 0)
      const cadRefunded = opts.data.reduce((t, item) => (t + item.data[5]), 0)
      const cadUsed = opts.data.reduce((t, item) => (t + item.data[6]), 0)

      if (opts.count === 0) return null

      return (
        <TableFooter>
          <TableRow className='MuiTableRow-root MUIDataTableHeadRow-root-117 MuiTableRow-head'>
            {opts.selectableRows !== 'none' ? <TableCell /> : null}
            {opts.columns.map((col, index) => {
              if (col.display === 'true') {
                if (col.name === 'area') {
                  return (
                    <TableCell key={index} className='MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-212 MUIDataTableHeadCell-fixedHeader-213' style={{ fontSize: '0.875rem' }}>
                      <div>Totais</div>
                    </TableCell>
                  )
                } else if (col.name === 'amountCad') {
                  return (
                    <TableCell key={index} className='MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-212 MUIDataTableHeadCell-fixedHeader-213' style={{ fontSize: '0.875rem' }}>
                      <div>{toNumber(cadSpent)}</div>
                    </TableCell>
                  )
                } else if (col.name === 'cadRefunded') {
                  return (
                    <TableCell key={index} className='MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-212 MUIDataTableHeadCell-fixedHeader-213' style={{ fontSize: '0.875rem' }}>
                      <div>{toNumber(cadRefunded)}</div>
                    </TableCell>
                  )
                } else if (col.name === 'cadUsed') {
                  return (
                    <TableCell key={index} className='MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-212 MUIDataTableHeadCell-fixedHeader-213' style={{ fontSize: '0.875rem' }}>
                      <div>{toNumber(cadUsed)}</div>
                    </TableCell>
                  )
                } else {
                  return <TableCell key={index} />
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      )
    }
  };

  if (loading) return <Loading />;

  return parkingData?.length === undefined ? (
    <ContainerForm onSubmit={fetchParking} className="animeLeft">
      <TitleButtons handleBtnBackClick={ () => setRedirectTo(RedirectTo.dashboard) }>
        <h3>Estacionamentos</h3>
      </TitleButtons>

      <strong>Parâmetros de pesquisa obrigatórios</strong>

      <InputGrid>
        <Column.Group className='mb-0'>
          <Column>
            <label for="startDate">Escolha a data de início</label>
            <input
              placeholder="Data início"
              type="date"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
              required
            />
          </Column>
          <Column>
            <label for="endDate">Escolha a data de fim</label>
            <input
              placeholder="Data fim"
              type="date"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
              required
            />
          </Column>
        </Column.Group>

        {loading ? (
          <button type="submit" disabled>
            Carregando...
          </button>
        ) : (
          <button type="submit">Visualizar dados</button>
        )}
      </InputGrid>
    </ContainerForm>
  ) : (
    <Container className="animeLeft">
      <TitleButtons handleBtnBackClick={ () => setParkingData({}) }>
        <h3>Estacionamentos</h3>
      </TitleButtons>

      <strong>
        {`Periodo do estacionamento: ${moment(startDate).format('DD/MM/YYYY')} a ${moment(endDate).format('DD/MM/YYYY')}`}
      </strong>

      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title=''
          columns={columns}
          data={parkingData}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  );
}
