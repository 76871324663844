import styled from 'styled-components'

export const Container = styled.div`
  input#search {
    background: white;
    height: 2rem;
    font-size: 12px;
    margin-bottom: 0.4rem;

    & + div {
      margin-top: 0rem;
    }

  }

  .control.has-icons-left .icon {
    height: 2em;
  }
`

export const AddressList = styled.div`
  display: ${(props) => props.references?.length >= 4 ? 'block' : 'none'};
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  width: 450px;
  top: 38px;

  @media (max-width: 768px) {
    width: 330px;
  }
`

export const AddressItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 35px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 12px;
  color: #515151;

  &:hover {
    background-color: var(--input-background);
    cursor: pointer;
  }
`

export const ItemIcon = styled.span`
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
  background-position: -1px -161px;
`
