import styled from "styled-components";

export const Container = styled.header`
  background: transparent;
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  padding: 2rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    max-width: 30em;
  }

  @media (max-width: 720px) {
    justify-content: center;
  }
`;
