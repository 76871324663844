import { ResendCode } from "../../components/Forms/ResendCode";
import LayoutHomePage from "../LayoutHomePage";

export default function ResendCodePage() {
  return (
    <LayoutHomePage>
      <ResendCode />
    </LayoutHomePage>
  );
}
