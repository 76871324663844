import { useState, useEffect } from 'react';
import { Card, Table, Modal, Box, Button, Progress, Icon, Column, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import UserService from '../../../services/user';
import { PaginationCustom } from '../../shared/PaginationCustom';
import { toast } from 'react-toastify';
import { CardHeader } from '../../shared/CardHeader';
import { BoxHeader } from '../../shared/BoxHeader';
import { toCurrency, toNumber } from '../../../helpers/toCurrency'
import moment from 'moment'
import { ProofService } from '../../../services/api-zad';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

export const AppPurchaseHistoryList = ({ userData, goToMenu }) => {
  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [activeProgress, setActiveProgress] = useState(true);
  const [activeModal, setActiveModal] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);

  const cadPaid = purchases
    .filter((purchase) => (purchase?.status === 'paid' && purchase?.payment !== 'Cortesia'))
    .reduce((total, purchase) => (total + purchase?.quantity), 0)

  const cadCourtesy = purchases
    .filter((purchase) => (purchase?.status === 'paid' && purchase?.payment === 'Cortesia'))
    .reduce((total, purchase) => (total + purchase?.quantity), 0)

  useEffect(() =>{
    fetchPurchases();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const fetchPurchases = async () => {
    try {
      const response = await UserService.purchaseHistory(userData.id);
      setPurchases(response.data.data);
    } catch(e) {
      if (e.response.data)
        toast.error(e.response.data.message)
      else
        toast.error(e.response.message)
    }
    setActiveProgress(false);
  }
  
  const toggleModal = () => setActiveModal(!activeModal);

  const openModal = (index) => {
    toggleModal();
    setSelectedPurchase(index);
  }

  const onChangePage = (ppageOfItems) => {
    setPageOfItems(ppageOfItems);
  }

  const sendEmail = async (authenticateCode) => {
    if (!email) {
      toast.error("Informe um email válido"); return;
    }
    setSending(true)
    try {
      const response = await ProofService.buy({ email, authenticateCode });
      toast.success(response.data.message)
    } catch(e) {
      toast.error(e.response.data?.message || e.response.message)
    }
    setSending(false)
  }

  const renderModal = () => {
    if (selectedPurchase !== null) {
      const purchase = pageOfItems[selectedPurchase];
      return (
        <Box>
          <BoxHeader title='Detalhes da Compra' handleClose={toggleModal} />
          <Column.Group className='mb-0' breakpoint='mobile'>
            <Column className='pb-0'>
              <small><strong>Data da compra</strong></small><br />
              {purchase.date}
            </Column>
            <Column className='pb-0'>
              <small><strong>Quantidade CAD</strong></small><br />
              {purchase.quantity} por {toCurrency(purchase.value)}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <Column.Group className='mb-0' breakpoint='mobile'>
            <Column className='pb-0'>
              <small><strong>Forma Pagamento</strong></small><br />
              {purchase.payment}
            </Column>
            <Column className='pb-0'>
              <small><strong>Canal de Compra</strong></small><br />
              {purchase.channel}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <Column.Group className='mb-0' breakpoint='mobile'>
            <Column className='pb-0'>
              <small><strong>Código Autenticação</strong></small><br />
              {purchase.authenticateCode}
            </Column>
            <Column className='pb-0'>
              <small><strong>ID Pagarme</strong></small><br />
              {purchase.pagarmeTransactionId}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <small><strong>Situação</strong></small><br />
          {purchase.statusBr.toUpperCase()}

          <hr className="hr" />
          <Column.Group className='mb-0' breakpoint='mobile'>
            <Column className='pb-0'>
              <small><strong>Pago em</strong></small><br />
              {
                purchase.paidAt ? moment(purchase.paidAt).format('DD/MM/YYYY HH:mm:ss') : '-'
              }
            </Column>
            <Column className='pb-0'>
              <small><strong>Estornado em</strong></small><br />
              {
                purchase.refundedAt ? moment(purchase.refundedAt).format('DD/MM/YYYY HH:mm:ss') : '-'
              }
            </Column>
          </Column.Group>

          <hr className="hr" />
          <small><strong>Enviar comprovante</strong></small><br />
          <div className='is-flex is-align-items-center' style={{ gap:'1rem' }}>
            <Input
              name="email"
              placeholder='Informe o email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Icon
              color="custom-purple"
              style={{ cursor:'pointer' }}
              title='Enviar'
              onClick={() => sendEmail(purchase.authenticateCode)}
            >
              {
                sending
                  ? <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' size='2x' />
                  : <FontAwesomeIcon icon={faTelegram} size='2x' />
              }
            </Icon>
          </div>
        </Box>
      )
    }
  }

  return (
    <>
      <Card>
        <CardHeader handleClose={goToMenu}>Histórico de compras</CardHeader>
        <Card.Content>
          <strong>Resumo:</strong>
          <p className="mb-0">
            {toNumber(cadPaid)} pagos + {cadCourtesy} cortesias = {toNumber(cadPaid + cadCourtesy)} CAD
          </p>
          <hr className='hr my-2'/>
          <div className="table-container">
            <Table fullwidth>
              <Table.Head>
                <Table.Row>
                  <Table.Heading >Data</Table.Heading>
                  <Table.Heading textAlign={'centered'}>CAD</Table.Heading>
                  {/* <Table.Heading textAlign={'right'} className="is-hidden-mobile">Valor</Table.Heading> */}
                  <Table.Heading className="is-hidden-mobile">Forma</Table.Heading>
                  <Table.Heading className="is-hidden-mobile">Status</Table.Heading>
                  <Table.Heading></Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {
                  (pageOfItems.length === 0) ? (
                    <Table.Row key={0}>
                      <Table.Cell colSpan="9">
                        { activeProgress ? <Progress size='small' color='custom-purple' /> : 'Não há compras para este usuário.' }
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    pageOfItems.map((purchase, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{purchase.date}</Table.Cell>
                        <Table.Cell textAlign={'centered'}>{purchase.quantity}</Table.Cell>
                        {/* <Table.Cell textAlign={'right'} className="is-hidden-mobile">{toDecimal(purchase.value)}</Table.Cell> */}
                        <Table.Cell className="is-hidden-mobile">{purchase.payment}</Table.Cell>
                        <Table.Cell className="is-hidden-mobile">{purchase.payment === 'Cortesia' ? purchase.payment : purchase.statusBr}</Table.Cell>
                        <Table.Cell>
                          <Icon size={'medium'} className='is-clickable ml-2' title='Detalhes' onClick={() => openModal(index)} >
                            <FontAwesomeIcon icon={faInfoCircle} size='lg' />
                          </Icon>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  )
                }
              </Table.Body>
            </Table>
          </div>
          <div className="is-flex is-justify-content-space-between is-align-items-center">
            <Button type="button" onClick={goToMenu} color='danger' outlined>
              Fechar
            </Button>
            <PaginationCustom items={purchases} onChangePage={onChangePage} />
          </div>
        </Card.Content>
      </Card>

      <Modal closeOnBlur={true} closeOnEsc={true} active={activeModal} onClose={toggleModal}>
        <Modal.Background onClick={toggleModal} />
        <Modal.Content>
          { renderModal() }
        </Modal.Content>
        <Modal.Close />
      </Modal>
    </>
  )
}
