import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './helpers/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import IrregularVehiclesPage from './pages/IrregularVehiclesPage';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ActivatePage from './pages/ActivatePage';
import ResendCodePage from './pages/ResendCodePage';
import ParkingPage from './pages/ParkingPage';
import MonitorsPage from './pages/MonitorsPage';
import MonitorsNowPage from './pages/MonitorsNowPage';
import FinancialAppPdvPage from './pages/FinancialAppPdvPage';
import FinancialMonitorPage from './pages/FinancialMonitorPage';

import AgentIrregularVehiclesPage from './pages/AgentPage/IrregularVehicles';
import AgentConfirmedIrregularityPage from './pages/AgentPage/ConfirmedIrregularity';
import AgentFinedIrregularityPage from './pages/AgentPage/FinedIrregularity'

import { UserDatasheetPage } from './pages/UserPage/datasheet/index';
import { UsearSearchPage } from './pages/UserPage/search';
import { RedirectTo } from './utils/redirectTo';
import { UserNewPage } from './pages/UserPage/new';
import { UserEditPage } from './pages/UserPage/edit';
import { HolidayPage } from './pages/HolidayPage';
import { ContactUsPage } from './pages/ContactUsPage';
import { VacancyPage } from './pages/VacancyPage';
import { AreaPage } from './pages/AreaPage';
import { VehicleHistoricPage } from './pages/VehicleHistoricPage';
import { AccountabilityPage } from './pages/AccountabilityPage';
import { DataExtractionPage } from './pages/DataExtractionPage';
import { TimeRulePage } from './pages/TimeRulePage';
import { RegionRulePage } from './pages/RegionRulePage';
import { DomainBlacklistPage } from './pages/DomainBlacklistPage';
import { UserRemovePage } from './pages/UserPage/remove';

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path={RedirectTo.login} component={LoginPage} />
          <Route exact path={RedirectTo.activate} component={ActivatePage} />
          <Route exact path={RedirectTo.resendCode} component={ResendCodePage} />
          <Route exact path={RedirectTo.forgotPassword} component={ForgotPasswordPage} />
          <ProtectedRoute exact path={RedirectTo.dashboard} component={DashboardPage} />
          <ProtectedRoute exact path={RedirectTo.parking} component={ParkingPage} />
          <ProtectedRoute exact path={RedirectTo.monitors} component={MonitorsPage} />
          <ProtectedRoute
            exact
            path="/financialAppPdv"
            component={FinancialAppPdvPage}
          />
          <ProtectedRoute
            exact
            path="/financialMonitor"
            component={FinancialMonitorPage}
          />
          <ProtectedRoute
            exact
            path="/monitorsNow"
            component={MonitorsNowPage}
          />
          <ProtectedRoute
            exact
            path="/irregularVehicles"
            component={IrregularVehiclesPage}
          />

          {/*Rotas do Usuário Agent / Fiscal*/}
          <ProtectedRoute exact
            path="/agent/irregularVehicles/:area"
            component={AgentIrregularVehiclesPage}
          />
          <ProtectedRoute exact
            path="/agent/confirmedIrregularity"
            component={AgentConfirmedIrregularityPage}
          />
          <ProtectedRoute exact
            path="/agent/finedIrregularity"
            component={AgentFinedIrregularityPage}
          />

          {/* Rotas do Usuário Suporte */}
          <ProtectedRoute exact path='/user/new' component={UserNewPage} />
          <ProtectedRoute exact path='/user/new/:cpf' component={UserNewPage} />
          <ProtectedRoute exact path='/user/edit' component={UserEditPage} />
          <ProtectedRoute exact path='/user/search' component={UsearSearchPage} />
          <ProtectedRoute exact path='/user/search/:cpf' component={UsearSearchPage} />
          <ProtectedRoute exact path='/user/datasheet' component={UserDatasheetPage} />
          <ProtectedRoute exact path='/user/remove' component={UserRemovePage} />

          <ProtectedRoute exact path='/holidays' component={HolidayPage} />

          <ProtectedRoute exact path='/contactUs' component={ContactUsPage} />

          <ProtectedRoute exact path='/vacancy' component={VacancyPage} />

          <ProtectedRoute exact path='/areas' component={AreaPage} />

          <ProtectedRoute exact path='/vehicleHistoric' component={VehicleHistoricPage} />

          <ProtectedRoute exact path='/accountability' component={AccountabilityPage} />

          <ProtectedRoute exact path='/dataExtraction' component={DataExtractionPage} />

          <ProtectedRoute exact path='/timeRules' component={TimeRulePage} />

          <ProtectedRoute exact path='/regionRules' component={RegionRulePage} />

          <ProtectedRoute exact path='/domainBlacklist' component={DomainBlacklistPage} />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
