export const searchTypes = [
  { value: 'cpfCnpj', description: 'CPF ou CNPJ', pattern: '(^[0-9]{3}[.][0-9]{3}[.][0-9]{3}[-][0-9]{2}$)|(^[0-9]{2}[.][0-9]{3}[.][0-9]{3}[/][0-9]{4}[-][0-9]{2}$)', title: '123.123.123-12 para CPF ou 12.123.123/0001-12 para CNPJ' },
  { value: 'email', description: 'EMAIL', pattern: '.{3,40}', title: 'nome@email.com' },
  { value: 'name', description: 'NOME', pattern: '.{3,20}', title: 'Nome de no mínimo 3 letras' },
  { value: 'licensePlate', description: 'PLACA VEICULO', pattern: '^[a-zA-Z]{3}[0-9][a-zA-Z0-9][0-9]{2}', title: 'ABC1234 ou ABC1C34' },
];

export const Constant = {
  linkExternalBI: 'https://app.powerbi.com/view?r=eyJrIjoiZDQ1NTZmMDgtZWFjMi00MGNlLWE2NmMtYmJjNjI3YTc4MGVlIiwidCI6IjNkZjAwNzIxLTEyYjctNGM5NC04ODk1LTljNmEwNmNiYTg0ZiJ9',
  DiretoriaBI: 'https://app.powerbi.com/view?r=eyJrIjoiYjI4YmY2NzYtMmRkNC00NGY0LWI4OGQtZTg1ZjJmODVjOGY4IiwidCI6IjNkZjAwNzIxLTEyYjctNGM5NC04ODk1LTljNmEwNmNiYTg0ZiJ9'
}
