import moment from 'moment'
import { Box, Column, Icon, Input } from 'rbx'
import { BoxHeader } from '../../shared/BoxHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { ProofService } from '../../../services/api-zad'

export const VehicleCompare = ({ closeModal, irregularity={}, parking={} }) => {
  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);

  const irregularityDuration = moment.utc( moment(irregularity.endDate).diff(moment(irregularity.startDate)) ).format('HH[h] mm[m] ss[s]')
  const parkingDuration = moment.utc( moment(parking.endDate).diff(moment(parking.startDate)) ).format('HH[h] mm[m] ss[s]')

  const sendEmail = async (authenticateCode) => {
    if (!email) {
      toast.error("Informe um email válido"); return;
    }
    setSending(true)
    try {
      const response = await ProofService.park({ email, authenticateCode });
      toast.success(response.data.message)
    } catch(e) {
      toast.error(e.response.data?.message || e.response.message)
    }
    setSending(false)
  }

  return (
    <Box>
      <BoxHeader title={'Placa: ' + (irregularity.licensePlate || parking.licensePlate)} handleClose={closeModal} />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
        </Column>
        <Column className="pb-0">
          <small><strong>Aviso de Irregularidade</strong></small>
        </Column>
        <Column className="pb-0">
          <small><strong>Ativação do CAD</strong></small>
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Data: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? moment(irregularity.startDate).format('DD/MM/YYYY') : '-'}
        </Column>
        <Column className="pb-0">
          {parking ? moment(parking.startDate).format('DD/MM/YYYY') : '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Início: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? moment(irregularity.startDate).format('HH:mm:ss') : '-'}
        </Column>
        <Column className="pb-0">
          {parking ? moment(parking.startDate).format('HH:mm:ss') : '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Fim: </strong></small>
        </Column>
        <Column className="pb-0">
          {
            irregularity
              ? irregularity.endDate ? moment(irregularity.endDate).format('HH:mm:ss') : 'Irregular'
              : '-'
          }
        </Column>
        <Column className="pb-0">
          {
            parking
              ? parking.endDate ? moment(parking.endDate).format('HH:mm:ss') : 'Ativo'
              : '-'
          }
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Duração: </strong></small>
        </Column>
        <Column className="pb-0">
          {
            irregularity
              ? irregularity.endDate ? irregularityDuration : '-'
              : '-'
          }
        </Column>
        <Column className="pb-0">
          {
            parking
              ? parking.endDate ? parkingDuration : '-'
              : '-'
          }
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Pago em: </strong></small>
        </Column>
        <Column className="pb-0">
          { irregularity?.paidAt ? moment(irregularity.paidAt).format('DD/MM/YYYY HH:mm:ss') : '-' }
        </Column>
        <Column className="pb-0">
          { '-' }
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Local: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? irregularity.local : '-'}<br/>
          {
            irregularity.reference ? (
              `Referencia: ${irregularity.reference}`
            ) : ('')
          }
        </Column>
        <Column className="pb-0">
          {parking ? parking.addressName : '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Feito por: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity.createdBy || '-'}
        </Column>
        <Column className="pb-0">
          {parking.createdBy || '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <Column.Group className="mb-0" breakpoint='mobile'>
        <Column className="pb-0" size="one-fifth">
          <small><strong>Perfil: </strong></small>
        </Column>
        <Column className="pb-0">
          {irregularity ? 'Monitor' : '-'}
        </Column>
        <Column className="pb-0">
          {parking ? parking.profileFrom : '-'}
        </Column>
      </Column.Group>
      <hr className="hr" />

      <small><strong>Enviar comprovante de ativação</strong></small><br />
      <div className='is-flex is-align-items-center' style={{ gap:'1rem' }}>
        <Input
          name="email"
          placeholder='Informe o email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Icon
          color="custom-purple"
          style={{ cursor:'pointer' }}
          title='Enviar'
          onClick={() => sendEmail(parking.authenticateCode)}
        >
          {
            sending
              ? <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' size='2x' />
              : <FontAwesomeIcon icon={faTelegram} size='2x' />
          }
        </Icon>
      </div>
    </Box>
  )
}
