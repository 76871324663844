import { useState } from 'react'
import { Button, Column, Control, Icon, Image, Input } from 'rbx'
import { HeatmapLayer } from '@react-google-maps/api'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import Loading from '../../../helpers/Loading'
import moment from 'moment'
import Controls from '../../shared/Controls/Controls'
import { Redirect } from 'react-router-dom'
import { RedirectTo } from '../../../utils/redirectTo'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import pinMonitorOnline from '../../../assets/pin-monitor-online.png'
import { ZadMap } from '../../shared/ZadMap'

const queryConfig = {
  date: moment().format('YYYY-MM-DD'),
  startTime: '08:00',
  endTime: '19:00'
}

export const FindMonitors = () => {
  const [monitor, setMonitor] = useState('')
  const [date, setDate] = useState(queryConfig.date)
  const [startTime, setStartTime] = useState(queryConfig.startTime)
  const [endTime, setEndTime] = useState(queryConfig.endTime)
  const [query, setQuery] = useState(queryConfig)
  const [redirectTo, setRedirectTo] = useState(null)

  const { 
    response: areasResponse,
    load: areaLoad
  } = useFetch(`${API_URL}/areas`)

  const areasMap = areasResponse?.data||[]

  const { response: monitoresData } = useFetch(`${API_URL}/adm/monitors?deleted=false`)

  const monitores = monitoresData?.data?.map((m) => ({ value: m.id, label: m.name }))

  const { 
    response: locationMonitorsResponse,
    load: locationMonitorsLoad,
    refresh: locationMonitorsRefresh
  } = useFetch(`${API_URL}/adm/location-history-monitors?${new URLSearchParams(query)}`, true)

  // console.log('FindMonitors', 'Rendered')

  if (redirectTo)
  return <Redirect to={{ pathname: redirectTo }} />

  const handleSubmit = (event) => {
    event.preventDefault()
    if (date && startTime && endTime) {
      if (date === query.date && startTime === query.startTime && endTime === query.endTime) {
        locationMonitorsRefresh()
      } else {
        setQuery({
          date,
          startTime,
          endTime
        })
      }
    }
  }

  const searchContainerStyle = {
    position: 'absolute',
    top: 6,
    left: 6,
    zIndex: 4,
    pointerEvents: 'auto',
    overflow: 'hidden',
    whiteSpace: 'normal',
    fontFamily: '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    width: '100%',
    maxWidth: '60rem'
  }

  const inputStyle = {
    background: 'white',
    padding: '1.5rem 0.5rem',
  }

  let paths = []
  if (locationMonitorsResponse?.data) {
    const filteredPaths = monitor
      ? locationMonitorsResponse?.data?.paths.filter((path) => path.userId === monitor.value)
      : locationMonitorsResponse?.data?.paths || []

    for (const path of filteredPaths) {
      paths.push(new window.google.maps.LatLng(path.lat, path.lng))
    }
  }

  const optionsHeatmapLayer = {
    radius: 15,
    opacity: 1
  }

  return !areaLoad ? (
    <>
      <div className='search-container' style={searchContainerStyle}>
        <form onSubmit={handleSubmit}>
          <Column.Group gapSize={8}>
            <Column className='pb-0'>
              <Column.Group gapSize={1} breakpoint='mobile'>
                <Column>
                  <Control iconLeft >
                    <Input 
                      style={{ background: 'white', padding: '1.5rem 0.3rem 1.5rem 2.5rem', }}
                      name="date"
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                    <Icon className='is-clickable pt-2' align="left" onClick={() => setRedirectTo(RedirectTo.dashboard)}>
                      <Image src={pinMonitorOnline} title='Home' />
                    </Icon>
                  </Control>
                </Column>
                <Column className='is-one-third'>
                  <Controls.TextField 
                    style={inputStyle}
                    name="startTime"
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </Column>
                <Column className='is-one-third'>
                  <Controls.TextField 
                    style={inputStyle}
                    name="endTime"
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </Column>
              </Column.Group>
            </Column>

            <Column>
              <Column.Group gapSize={1} breakpoint='mobile'>
                <Column className=''>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '0.3rem 0rem'
                      }),
                      menu: (baseStyles, state) => ({
                        ...baseStyles,
                        position: 'relative'
                      }),
                    }}
                    classNamePrefix="select"
                    defaultValue={monitores?.filter(m => m.value === monitor?.value)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="monitor"
                    options={monitores}
                    onChange={(e) => setMonitor(e)}
                  />
                </Column>
                <Column className='is-one-fifth'>
                  <Button style={{ padding:'1.5rem 1rem' }}>
                    {
                      locationMonitorsLoad
                        ? <FontAwesomeIcon icon={faSpinner} pulse />
                        : <FontAwesomeIcon icon={faSearch} />
                    }
                  </Button>
                </Column>
              </Column.Group>
            </Column>
          </Column.Group>          
        </form>
      </div>

      <ZadMap areasMap={areasMap} mapContainerStyle={{ width: '100vw', height: '100vh' }} zoom={17}>
        <HeatmapLayer data={paths} options={optionsHeatmapLayer} />
      </ZadMap>
    </>
  ) : <Loading />
}
