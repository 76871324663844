import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import addBlueImg from '../../../assets/add-blue.svg';
import removeBlueImg from '../../../assets/remove-blue.svg';
import successImg from '../../../assets/success.svg';
import pixImg from '../../../assets/pix.svg';
import creditCardImg from '../../../assets/creditCard.svg';
import { maskCpfCnpj, maskQuantityCads, unMask } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import {
  Container,
  Result,
  RadioContainer,
  RadioContainerPayment,
  RadioBox,
  ValueContainer,
  IconButton,
  IconButtonContainer,
} from './styles';
// import QRCode from 'react-qr-code';


export function SellCadsModal({ isOpen, onRequestClose }) {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [quantity, setQuantity] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [requestSuccess, setRequestSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  // const [pixQrCode, setPixQrCode] = useState('');

  // const profile = localStorage.getItem('profile');

  const handleQuantity = (operation) => {
    if (operation === 'add' && quantity < 999) {
      setQuantity(+quantity + 1);
    }

    if (operation === 'remove' && quantity > 1) {
      setQuantity(+quantity - 1);
    }
  };

  const handleSellCads = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (paymentMethod === '') {
      toast.error('Selecione uma forma de pagamento.');
      setLoading(false);
      return;
    }

    if (quantity === '0' || quantity === '00' || quantity === '000') {
      toast.error('Informe uma quantidade maior que zero.');
      setLoading(false);
      return;
    }

    const body = {
      cpfCnpj: unMask(cpfCnpj),
      quantity: +quantity,
      paymentMethod,
    };

    const res = await fetch(`${API_URL}/monitors/cad-sale`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setRequestSuccess(response.message);
      // if (paymentMethod === 'pix') {
      //   setPixQrCode(response.data.pixQrCode);
      // }
    }

    setLoading(false);
  };

  const cleanFields = () => {
    setCpfCnpj('')
    setQuantity('')
    setPaymentMethod('')
    setRequestSuccess('')
    // setPixQrCode('')
  }

  const handleCloseModal = () => {
    cleanFields()
    onRequestClose()
    // if (paymentMethod === 'pix') {
    //   if (window.confirm('A leitura do QR CODE já foi realizada ?')) {
    //     cleanFields()
    //     onRequestClose()
    //   }
    // } else {
    //   cleanFields()
    //   onRequestClose()
    // }
  }

  const RadioBoxCad = ({ cads }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => setQuantity(cads)}
        isActive={quantity === cads}
        activeColor="green"
      >
        <span>
          <span>{cads}</span> Cartões
        </span>
      </RadioBox>
    )
  }

  const RadioContainerBox = ({ values=[] }) => {
    return (
      <RadioContainer>
        {values.map(cads => (
          <RadioBoxCad key={cads} cads={cads} />
        ))}
      </RadioContainer>
    )
  }

  const RadioBoxPayment = ({ label, method, image }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => setPaymentMethod(method)}
        isActive={paymentMethod === method}
        activeColor="green"
      >
        <img src={image} alt={label} />
        <span>{label}</span>
      </RadioBox>
    )
  }

  if (requestSuccess !== '')
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={handleCloseModal}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>

        <Result className="animeLeft">
          <h2>{requestSuccess}</h2>
          <img src={successImg} alt="Sucesso" onClick={handleCloseModal} />
          {/* {
            paymentMethod === 'pix' ? (
              <QRCode value={pixQrCode} />
            ) : (
              <img src={successImg} alt="Sucesso" onClick={handleCloseModal} />
            )
          } */}
        </Result>
      </Modal>
    );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleCloseModal}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleSellCads}>
        <h2>Vender Cartões</h2>

        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <RadioContainerBox values={[10, 20, 30]} />

        <MaskedInput
          mask={maskQuantityCads}
          placeholder="Outra quantidade"
          guide={false}
          type="text"
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
          required
        />
        <IconButtonContainer>
          <IconButton type="button" onClick={() => handleQuantity('remove')}>
            <img src={removeBlueImg} alt="Menos" />
          </IconButton>

          <IconButton type="button" onClick={() => handleQuantity('add')}>
            <img src={addBlueImg} alt="Mais" />
          </IconButton>
        </IconButtonContainer>

        <ValueContainer>
          <h3>
            Valor total: <span>R$ {+quantity * 2},00</span>
          </h3>
        </ValueContainer>

        <RadioContainerPayment>
          <RadioBoxPayment label='Pix' method='pix' image={pixImg} />
          <RadioBoxPayment label='Crédito' method='credit_card' image={creditCardImg} />
        </RadioContainerPayment>

        {loading ? (
          <button type="submit" disabled>
            Vendendo
          </button>
        ) : (
          <button type="submit">Vender</button>
        )}
      </Container>
    </Modal>
  );
}
