import { useState, useEffect } from "react";
import { API_URL } from "../../../services/api";
import { SellCadsModal } from "../../Modals/SellCadsModal";
import { ParkingModal } from "../../Modals/ParkingModal";
import { SearchVehicle } from "../../Monitor/SearchVehicle";
import { HistoryUse } from "../../Monitor/HistoryUse";
import { HistorySells } from "../../Monitor/HistorySells";
import { VehiclesParked } from "../../Monitor/VehiclesParked";
import { CreateNewUserAppModal } from "../../Modals/CreateNewUserAppModal";
import { SearchCadsUserModal } from "../../Modals/SearchCadsUserModal";
import { Container, Group } from "./styles";
import { QuickAccess } from "../../Layout/QuickAccess";
import { faDollarSign, faMapMarkerAlt, faSearchDollar, faUser } from "@fortawesome/free-solid-svg-icons";


export function DashboardMonitor() {
  const [vehiclesParkedData, setVehiclesParkedData] = useState([]);
  const [historyUseData, setHistoryUseData] = useState([]);
  const [historySellsData, setHistorySellsData] = useState([]);
  const [isSellCadsModalOpen, setIsSellCadsModalOpen] = useState(false);
  const [isParkingModalOpen, setIsParkingModalOpen] = useState(false);
  const [isCreateNewUserAppModalOpen, setIsCreateNewUserAppModalOpen] = useState(false);
  const [isSearchCadsUserModalOpen, setIsSearchCadsUserModalOpen] = useState(false);

  useEffect(() => {
    fetchHistoryUse();
    fetchVehiclesParked();
    fetchHistorySells();
  }, []);

  async function fetchHistoryUse() {
    const res = await fetch(`${API_URL}/historic-parkings`, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("token"),
      },
    });
    const response = await res.json();
    const body = response.data.indexParking;
    setHistoryUseData(body);
  }

  async function fetchHistorySells() {
    const res = await fetch(`${API_URL}/historic-sale-cad`, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("token"),
      },
    });
    const response = await res.json();
    const body = response.data.sales;
    setHistorySellsData(body);
  }

  async function fetchVehiclesParked() {
    const res = await fetch(`${API_URL}/parkings`, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("token"),
      },
    });
    const response = await res.json();
    const body = response.data;
    setVehiclesParkedData(body);
  }

  const toggleCreateNewUserAppModal = () => {
    setIsCreateNewUserAppModalOpen(!isCreateNewUserAppModalOpen);
  };

  const toggleSearchCadsUserModal = () => {
    setIsSearchCadsUserModalOpen(!isSearchCadsUserModalOpen);
  };

  const toggleOpenSellCadsModal = () => {
    if (isSellCadsModalOpen) fetchHistorySells()
    setIsSellCadsModalOpen(!isSellCadsModalOpen)
  }

  const toggleOpenParkingModal = () => {
    if (isParkingModalOpen) {
      fetchVehiclesParked()
      fetchHistoryUse()
      fetchHistorySells()
    }
    setIsParkingModalOpen(!isParkingModalOpen)
  }

  const items = [
    { to: "#", icon: faMapMarkerAlt, title: 'Estacionar', click: true, callback: toggleOpenParkingModal },
    { to: "#", icon: faDollarSign, title: 'Vender', click: true, callback: toggleOpenSellCadsModal },
    { to: "#", icon: faSearchDollar, title: 'Consultar Saldo', click: true, callback: toggleSearchCadsUserModal },
    { to: "#", icon: faUser, title: 'Novo Usuário', click: true, callback: toggleCreateNewUserAppModal },
  ]

  return (
    <>
      <QuickAccess items={items} />
      <Container>
        <CreateNewUserAppModal
          isOpen={isCreateNewUserAppModalOpen}
          onRequestClose={toggleCreateNewUserAppModal}
        />

        <SearchCadsUserModal
          isOpen={isSearchCadsUserModalOpen}
          onRequestClose={toggleSearchCadsUserModal}
        />

        <SellCadsModal
          isOpen={isSellCadsModalOpen}
          onRequestClose={toggleOpenSellCadsModal}
        />

        <ParkingModal
          isOpen={isParkingModalOpen}
          onRequestClose={toggleOpenParkingModal}
        />

        <Group className="animeLeft">
          <SearchVehicle registrarIrregularidade={true} />
          <VehiclesParked
            vehiclesParkedData={vehiclesParkedData}
            fetchVehiclesParked={fetchVehiclesParked}
          />
        </Group>

        <Group className="animeLeft">
          <HistoryUse historyUseData={historyUseData} />

          <HistorySells historySellsData={historySellsData} />
        </Group>
      </Container>
    </>
  );
}
