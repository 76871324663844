import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import pixImg from '../../../assets/pix.svg';
import creditCardImg from '../../../assets/creditCard.svg';
import { maskPlate } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import {
  Container,
  InputsGrid,
  RadioContainerPayment,
  RadioBox,
  ValueContainer
} from './styles';
import { SearchParkingPlace } from '../../shared/SearchParkingPlace';
import { ParkingService } from '../../../services/api-zad';
import { toDecimal } from '../../../commons/utils';

export function ParkingModal({ isOpen, onRequestClose }) {
  const [addressName, setAddressName] = useState('')
  const [ruleName, setRuleName] = useState('');
  const [rules, setRules] = useState([]);
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [licensePlate, setLicensePlate] = useState('')
  const [amountCad, setAmountCad] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [loading, setLoading] = useState('')

  const cadValue = Number(process.env.REACT_APP_CAD_VALUE||0)
  const ruleSelected = rules.find(rule => rule.name === ruleName)

  useEffect(() => {
    cleanForm()
    fetchRules()
  }, [])

  const fetchRules = async () => {
    try {
      const response = await ParkingService.regionRules();
      setRules(response.data.data);
    } catch(e) {
      console.log(e, ' - ', e.message);
    }
  }

  const handleNewVehicle = async (e) => {
    e.preventDefault();

    if (!addressName) { toast.warn('Informe o local do estacionamento.'); return; }

    if (!latitude) { toast.warn('Latitude: não pode ser nulo.'); return; }

    if (!longitude) { toast.warn('Longitude: não pode ser nulo.'); return; }

    setLoading(true);

    const body = {
      addressName,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      licensePlate,
      ruleName,
      amountCad,
      paymentMethod,
      imei: process.env.REACT_APP_IMEI_DEFAULT,
      firebaseToken: ''
    };

    const res = await fetch(`${API_URL}/parkings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(onRequestClose, 3000);
    }

    cleanForm()
    setLoading(false)
  };

  const cleanForm = () => {
    setAddressName('')
    setRuleName('')
    setLatitude('')
    setLongitude('')
    setLicensePlate('')
    setAmountCad('')
    setPaymentMethod('')
  }

  const RadioBoxPayment = ({ label, method, image }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => setPaymentMethod(method)}
        isActive={paymentMethod === method}
        activeColor="green"
      >
        <img src={image} alt={label} />
        <span>{label}</span>
      </RadioBox>
    )
  }

  const OptionsForSelect = ({ quantity }) => {
    let options = []

    for (let i = 2; i <= quantity; i++) {
      options.push(<option key={i} value={i}>{i} Cartões</option>)
    }

    return (
      <>{options}</>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        cleanForm()
        onRequestClose()
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => {
          cleanForm()
          onRequestClose()
        }}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleNewVehicle}>
        <h2>Estacionar Veículo</h2>

        <MaskedInput
          mask={maskPlate}
          placeholder="Placa"
          guide={false}
          type="text"
          value={licensePlate.toUpperCase()}
          onChange={(event) => setLicensePlate(event.target.value)}
          required
        />

        <SearchParkingPlace 
          setAddress={setAddressName}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
        />

        <input
          placeholder="Local do estacionamento"
          value={addressName}
          disabled
        />
        <InputsGrid>
          <input
            placeholder="Latitude"
            value={latitude}
            disabled
          />
          <input
            placeholder="Longitude"
            value={longitude}
            disabled
          />
        </InputsGrid>

        <select
          type="text"
          value={ruleName}
          onChange={(event) => {
            setRuleName(event.target.value)
            setAmountCad('')
          }}
          required
        >
          <option value="" disabled defaultValue hidden>
            Regra do local
          </option>
          {
            rules.map(
              (rule, index) => (<option key={index} value={rule.name}>{rule.name}</option>)
            )
          }
        </select>

        <select
          type="text"
          value={amountCad}
          onChange={(event) => setAmountCad(event.target.value)}
          required
        >
          <option value="" disabled defaultValue hidden>
            Quantidade de Cartões
          </option>
          <option key={1} value="1">1 Cartão</option>
          { ruleSelected?.maxCad && <OptionsForSelect quantity={ruleSelected?.maxCad} /> }
        </select>

        <ValueContainer>
          <h3>
            Valor total: <span>R$ {toDecimal(+amountCad * cadValue)}</span>
          </h3>
        </ValueContainer>

        <RadioContainerPayment>
          <RadioBoxPayment label='Pix' method='pix' image={pixImg} />
          <RadioBoxPayment label='Crédito' method='credit_card' image={creditCardImg} />
        </RadioContainerPayment>

        {loading ? (
          <button type="submit" disabled>
            Estacionando
          </button>
        ) : (
          <button type="submit">Estacionar</button>
        )}
      </Container>
    </Modal>
  )
}
