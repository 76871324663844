import {
  faChevronUp,
  faMobile,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, Container, Footer } from "rbx";
import ativo16 from "../../assets/ativo-16.png";
import "./styles.scss";

export const FooterHome = () => {
  const company = process.env.REACT_APP_COMPANY||''
  const address = process.env.REACT_APP_COMPANY_ADDRESS||''
  const zipcod = process.env.REACT_APP_COMPANY_ZIPCOD||''
  const contact1 = process.env.REACT_APP_COMPANY_CONTACT_1||''
  const contact2 = process.env.REACT_APP_COMPANY_CONTACT_2||''
  return (
    <Footer className="footerhome">
      <Container>
        <Column.Group breakpoint="mobile">
          <Column size={2}>
            <img src={ativo16} alt="" className="img-ativo-192x" />
          </Column>
          <Column style={{ padding: "1rem" }}>
            <p>
              { company }
              <br />
              { address }
              <br />
              CEP: { zipcod }
              <br />
              <FontAwesomeIcon icon={faPhone} /> { contact1 } |{" "}
              <FontAwesomeIcon icon={faMobile} /> { contact2 }
            </p>
          </Column>
          <Column textAlign={"right"} size={2}>
            <a href="#topo" className="topo">
              <FontAwesomeIcon icon={faChevronUp} />
            </a>
          </Column>
        </Column.Group>
      </Container>
    </Footer>
  );
};
