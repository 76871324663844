import { Link } from "react-router-dom";
import logoImg from "../../assets/logo_zad.png";
import { Container, Content } from "./styles";

export function HeaderHome() {
  return (
    <Container>
      <Content>
        <Link to="/">
          <img src={logoImg} alt="Transitar Consultoria" />
        </Link>
      </Content>
    </Container>
  );
}
