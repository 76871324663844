import { useState } from 'react';
import { Card } from "rbx";
import { ParkingService } from '../../../services/api-zad';
import { placaMask, unMask } from '../../../commons/masks';
import Controls from '../../shared/Controls/Controls';
import Autocomplete from '../../shared/Autocomplete';
import { toast } from 'react-toastify';
import { CardHeader } from '../../shared/CardHeader';
import UserService from '../../../services/user';

export const AppRenewParkingForm = ({ userData, goToMenu }) => {
  const [licensePlate, setLicensePlate] = useState('');
  const [stateButton, setStateButton] = useState('');

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setStateButton("loading");
    try {
      const body = {
        userId: userData.id,
        licensePlate: unMask(licensePlate),
        imei: process.env.REACT_APP_IMEI_DEFAULT,
        firebaseToken: ''
      }
      const response = await ParkingService.renew(body);
      await UserService.reload();
      toast.success(response.data.message)
      goToMenu();
    } catch(e) {
      if (e.response.data)
        toast.error(`${e.response.status}: ${e.response.data.message}`)
      else
        toast.error(`${e.response.status}: ${e.response.data.errors}`)
    }
    setStateButton('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader handleClose={goToMenu}>Renovar estacionamento</CardHeader>
        <Card.Content>
          <Autocomplete
            suggestions={userData.vehicles.filter(v => v.parked).map(v => placaMask(v.licensePlate))}
            label="Placa do veículo"
            name="licensePlate"
            placeholder="ABC-1234"
            value={licensePlate}
            setValue={value => setLicensePlate( placaMask(value) )}
            autoFocus
            required
          />
          <Controls.ActionButtons
            linkName="Fechar"
            handleClickBack={goToMenu} 
            buttonState={stateButton} 
            buttonName='Renovar'
          />
        </Card.Content>
      </Card>
    </form>
  )

}
