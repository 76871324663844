import { Column, Section, Title, Container } from 'rbx'
import { ToastContainer } from 'react-toastify'
import { Header } from './Header/Header'
import './styles.scss'

export const Layout = ({ children, breadcrumb, controllerName }) => {
  return (
    <>
      <ToastContainer />
      <Header />
      <Section id="main" className="layout">
        <Container className="">
          <Title subtitle size={4} className='has-text-grey mb-2'>
            { controllerName }
          </Title>
          {/* <Column.Group className='mb-0' breakpoint="mobile" vcentered>
            <Column>
              <Title subtitle size={4} className='has-text-grey'>
                { controllerName }
              </Title>
            </Column>
            <Column textAlign='right'>
              <Title subtitle size={6} className='has-text-grey'>
                { breadcrumb }
              </Title>
            </Column>
          </Column.Group>           */}
          <Column.Group centered>
            <Column>
              { children }
            </Column>
          </Column.Group>
        </Container>
      </Section>
    </>
  )
}
