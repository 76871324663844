import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const token = localStorage.getItem('token');

  if (token) return <Route {...props} />;
  else return <Redirect to="/" />;
};

export default ProtectedRoute;
