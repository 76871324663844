import { Control, Label, Field, Input, Icon, Image } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import pinMonitorOnline from '../../../assets/pin-monitor-online.png'
import './styles.scss'

export const TextFieldWithSearch2 = ({ name, label, value, onChange, onClickFirst, onClickSecond, ...other }) => {
  const inputStyle = {
    background: '#fff',
    padding: '1.5rem 3rem',
    fontSize: 'revert'
  }

  const controlStyle = {
    // padding: 10
  }

  const iconStyle = {
    paddingLeft: '0.3rem',
    paddingTop: '0.3rem',
    fontSize: '1rem'
  }

  return (
    <>
      {(label) && <Label>{label}</Label>}
      <Field>
        <Control iconRight iconLeft style={controlStyle}>
          <Input 
            name={name}
            value={value}
            onChange={onChange}
            style={inputStyle}
            {...other}
          />
          <Icon color="black" align="left" onClick={onClickFirst} style={iconStyle}>
            <Image src={pinMonitorOnline} title='Home' />
          </Icon>
          <Icon color="black" align="right" onClick={onClickSecond} style={iconStyle}>
            <FontAwesomeIcon icon={faTimes} title='Limpar pesquisa' />
          </Icon>
        </Control>
      </Field>
    </>
  )
}
