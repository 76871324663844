import { useState } from 'react'
import { Navbar } from 'rbx'
import appIconImage from '../../../assets/app-icon.png'
import "../../../styles/header.scss"
import { Redirect } from "react-router-dom"
import { Sidebar } from '../Sidebar'
import { API_URL } from '../../../services/api'
import { celPhoneMask, cpfOrCnpjMask } from '../../../utils/masks'
import moment from 'moment'
import { UserModal } from '../../Modals/UserModal'
import { RedirectTo } from '../../../utils/redirectTo'
import { UserDropdown } from '../UserDropdown'


export const Header = () => {
  const [redirectTo, setRedirectTo] = useState(null)
  const [userData, setUserData] = useState({});
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const profile = localStorage.getItem('profile')

  async function fetchUser() {
    const id = localStorage.getItem("id");
    const res = await fetch(`${API_URL}/users/${id}`, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("token"),
      },
    });
    const response = await res.json();
    const body = response.data;
    body.cpf_cnpj = cpfOrCnpjMask(body.cpf_cnpj);
    body.phone = celPhoneMask(body.phone.replace("+55", ""));
    const birthDay = moment(body.birthDay, "YYYY-MM-DD");
    const birthdayMasked = birthDay.format("DD/MM/YYYY");
    body.birthDay = birthdayMasked;
    setUserData(body);
  }

  const toggleUserModal = () => {
    if (!isUserModalOpen) fetchUser();
    setIsUserModalOpen(!isUserModalOpen);
  }

  if (redirectTo && redirectTo!==window.location.pathname)
    return <Redirect to={{ pathname: redirectTo }} />

  return (
    <Navbar className="is-fixed-top is-flex is-justify-content-space-between">
      <Navbar.Brand className="ml-0">
        <div className='is-flex'>
          <Sidebar profile={profile} />
          <img src={appIconImage} alt="logoImage" onClick={()=>setRedirectTo(RedirectTo.dashboard)} style={{cursor: 'pointer'}} />
        </div>
      </Navbar.Brand>

      <Navbar.Segment align="end" className="navbar-item">
        <UserDropdown setRedirectTo={setRedirectTo} toggleUserModal={toggleUserModal} />
      </Navbar.Segment>
      <UserModal
        isOpen={isUserModalOpen}
        onRequestClose={toggleUserModal}
        userData={userData}
      />
    </Navbar>
  )
}
