import Geocode from 'react-geocode';

const GeocodeService = Geocode;

GeocodeService.setApiKey(process.env.REACT_APP_GEOCODE_KEY);
GeocodeService.setLocationType("ROOFTOP")
GeocodeService.setLanguage('pt-BR');
GeocodeService.setRegion('br');
GeocodeService.enableDebug();

export default GeocodeService;
