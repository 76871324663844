import { useEffect, useState } from 'react';
import moment from 'moment';
import { toDecimal, toNumber } from '../../../helpers/toCurrency';
import { useFetch } from '../../../hooks/useFetch';
import { API_URL } from '../../../services/api';
import { Container, InfoBoard, InfoCard } from './styles';
import { Column, Icon, Title } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Timer } from '../../shared/Timer';

export function Summary() {
  const [time, setTime] = useState(null)
  
  const { 
    response: dashboardResponse, 
    load, 
    refresh: dashboardRefresh
  } = useFetch(`${API_URL}/adm/dashboard`)

  const dashboardData = dashboardResponse?.data||{}

  const minutes = 1

  useEffect(() => {
    setTime(moment())

    if (!load) {
      const interval = setInterval(() => {
        dashboardRefresh()
        setTime(moment())
      }, minutes * 60 * 1000);

      return () => clearInterval(interval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // console.log('Summary', 'Rendered')

  return (
    <Container>
      <header>
        <Title size={4}>Irregularidades - CAD ativados</Title>
        <Title subtitle size={6} style={{ fontSize:'0.9rem' }}>
          Atualiza em: <Timer startDate={time} paidMinutes={minutes} reverse={true} format='mm[:]ss'/>
          {
            load && (
              <Icon className='fa-spin ml-2' size='small' style={{fontSize:'0.8rem'}}>
                <FontAwesomeIcon icon={faSyncAlt} />
              </Icon>
            )
          }
        </Title>
      </header>
      <InfoBoard columns={3} columnsBreak={3}>
        <InfoCard>
          <span className="red quantity" title="Avisos de irregularidade ativos">{toNumber(dashboardData?.irregularVehiclesNow)}</span>
          <span title="CAD ativos">{toNumber(dashboardData?.cadActivatedNow)}</span>
          <strong>agora</strong>
        </InfoCard>
        <InfoCard>
          <span className="red quantity" title="Avisos de irregularidade hoje">{toNumber(dashboardData?.irregularVehiclesToday)}</span>
          <span className="purple" title="CAD ativados hoje">{toNumber(dashboardData?.cadActivatedToday)}</span>
          <strong>hoje</strong>
        </InfoCard>
        <InfoCard>
          <span className="red quantity" title="Avisos de irregularidade no mês">{toNumber(dashboardData?.irregularVehiclesMonth)}</span>
          <span className="purple" title="CAD ativados no mês">{toNumber(dashboardData?.cadActivatedMonth)}</span>
          <strong>mês</strong>
        </InfoCard>
      </InfoBoard>

      <Column.Group className="mb-0">
        <Column className="py-0">
          <header>
            <Title size={4}>Arrecadação geral</Title>
          </header>
          <InfoBoard columns={2}>
            <InfoCard>
              <span className="purple quantity" title="CAD vendidos hoje">
                {
                  toNumber(
                    Number(dashboardData?.cadUsersSoldToday?.quantity) + 
                    Number(dashboardData?.cadPdvsSoldToday?.quantity) +
                    Number(dashboardData?.cadAdmSoldToday?.quantity)
                  )
                }
              </span>
              <span className="purple">
                {
                  toDecimal(
                    dashboardData?.cadUsersSoldToday?.total +
                    dashboardData?.cadPdvsSoldToday?.total +
                    dashboardData?.cadAdmSoldToday?.total
                  )
                }
              </span>
              <strong>hoje</strong>
            </InfoCard>
            <InfoCard>
              <span className="purple quantity" title="CAD vendidos no mês">
                {
                  toNumber(
                    Number(dashboardData?.cadUsersSoldMonth?.quantity) +
                    Number(dashboardData?.cadPdvsSoldMonth?.quantity) +
                    Number(dashboardData?.cadAdmSoldMonth?.quantity)
                  )
                }
              </span>
              <span className="purple">
                {
                  toDecimal(
                    dashboardData?.cadUsersSoldMonth?.total +
                    dashboardData?.cadPdvsSoldMonth?.total +
                    dashboardData?.cadAdmSoldMonth?.total
                  )
                }
              </span>
              <strong>mês</strong>
            </InfoCard>
          </InfoBoard>
        </Column>
        <Column className="py-0">
          <header>
            <Title size={4}>Taxa veículo irregular</Title>
          </header>
          <InfoBoard columns={2}>
            <InfoCard>
              <span className="blue quantity" title="Taxas pagas hoje">
                { toNumber(Number(dashboardData?.cadAdmSoldToday?.count)) }
              </span>
              <span className="blue">
                { toDecimal(dashboardData?.cadAdmSoldToday?.total) }
              </span>
              <strong>hoje</strong>
            </InfoCard>
            <InfoCard>
              <span className="blue quantity" title="Taxas pagas no mês">
                { toNumber(Number(dashboardData?.cadAdmSoldMonth?.count)) }
              </span>
              <span className="blue">
                { toDecimal(dashboardData?.cadAdmSoldMonth?.total) }
              </span>
              <strong>mês</strong>
            </InfoCard>
          </InfoBoard>
        </Column>
      </Column.Group>

      <header>
        <Title size={4}>Gateway pagamento</Title>
      </header>
      <InfoBoard>
        <InfoCard>
          <span>
            {toDecimal(dashboardData?.paymentGateway?.today?.find(p => p.gateway==='stone')?.total)}
          </span>
          Stone<strong>hoje</strong>
        </InfoCard>
        <InfoCard>
          <span>
            {toDecimal(dashboardData?.paymentGateway?.month?.find(p => p.gateway==='stone')?.total)}
          </span>
          Stone<strong>mês</strong>
        </InfoCard>
        <InfoCard>
          <span className="blue">
            {toDecimal(dashboardData?.paymentGateway?.today?.find(p => p.gateway==='pagarme')?.total)}
          </span>
          Pagar.me<strong>hoje</strong>
        </InfoCard>
        <InfoCard>
          <span className="blue">
            {toDecimal(dashboardData?.paymentGateway?.month?.find(p => p.gateway==='pagarme')?.total)}
          </span>
          Pagar.me<strong>mês</strong>
        </InfoCard>
      </InfoBoard>

      <header>
        <Title size={4}>CAD vendidos</Title>
      </header>
      <InfoBoard>
        <InfoCard>
          <span className="quantity">{toNumber(dashboardData?.cadUsersSoldToday?.quantity)}</span>
          <span>{toDecimal(dashboardData?.cadUsersSoldToday?.total)}</span>
          APP<strong>hoje</strong>
        </InfoCard>
        <InfoCard>
          <span className="quantity">{toNumber(dashboardData?.cadUsersSoldMonth?.quantity)}</span>
          <span>{toDecimal(dashboardData?.cadUsersSoldMonth?.total)}</span>
          APP<strong>mês</strong>
        </InfoCard>
        <InfoCard>
          <span className="blue quantity">{toNumber(dashboardData?.cadPdvsSoldToday?.quantity)}</span>
          <span className="blue">{toDecimal(dashboardData?.cadPdvsSoldToday?.total)}</span>
          PDV<strong>hoje</strong>
        </InfoCard>
        <InfoCard>
          <span className="blue quantity">{toNumber(dashboardData?.cadPdvsSoldMonth?.quantity)}</span>
          <span className="blue">{toDecimal(dashboardData?.cadPdvsSoldMonth?.total)}</span>
          PDV<strong>mês</strong>
        </InfoCard>
      </InfoBoard>
    </Container>
  );
}
