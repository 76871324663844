import { Layout } from "../../components/Layout";
import { DashboardAdm } from "../../components/Adm/Dashboard";
import { DashboardAgent } from "../../components/Agent/Dashboard";
import { DashboardPrefecture } from "../../components/Prefecture/Dashboard";
import { DashboardDiretoria } from "../../components/Diretoria/Dashboard";
import { DashboardMonitor } from "../../components/Monitor/Dashboard";


export default function DashboardPage() {
  const profile = localStorage.getItem("profile");
  return (
    <Layout>
      { ["adm","support"].includes(profile) && <DashboardAdm /> }
      { profile === "monitor" && <DashboardMonitor /> }
      { profile === "agent" && <DashboardAgent /> }
      { profile === "prefecture" && <DashboardPrefecture /> }
      { profile === "diretoria" && <DashboardDiretoria /> }
    </Layout>
  );
}
