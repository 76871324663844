import { Column } from "rbx";
import "../../../styles/user.scss";
import { Layout } from "../../../components/Layout";
import { UserForm } from '../../../components/shared/UserForm';
import { useParams } from "react-router-dom";

export const UserNewPage = () => {
  const { cpf } = useParams()
  return (
    <Layout>
      <Column.Group className="animeLeft" centered>
        <Column size={5}>
          <UserForm newUser={true} cpf={cpf} />
        </Column>
      </Column.Group>
    </Layout>
  )
}
