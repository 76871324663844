import { Api } from "./api";

const IrregularVehicleService = {
  confirmIrregularVehicle: (irregularId, body) => 
    Api.put(`/agents/confirm-irregular-vehicle/${irregularId}`, 
      body, 
      { headers: { 'Authorization': localStorage.getItem('token')} }
    ),

  fineIrregularVehicle: (irregularId) => 
    Api.put(`/agents/fine-irregular-vehicle/${irregularId}`, {},
      { headers: { 'Authorization': localStorage.getItem('token')} }
    ),

  findIrregularVehicles: (area='a') => 
    Api.get(`/agents/find-irregular-vehicles/${area}`,
      { headers: { 'Authorization': localStorage.getItem('token')} }
    ),

  findIrregularVehicle: (licensePlate) => 
    Api.get(`/agents/find-irregular-vehicle/${licensePlate}`,
      { headers: { 'Authorization': localStorage.getItem('token')} }
    ),

  confirmedIrregularityHistory: (cpfAgent='', startDate='', endDate='', fined='') => 
    Api.get(`/agents/confirmed-irregularity-history?cpfAgent=${cpfAgent}&startDate=${startDate}&endDate=${endDate}&fined=${fined}`,
      { headers: { 'Authorization': localStorage.getItem('token')} }
    ),
}

export default IrregularVehicleService