import Modal from 'react-modal';
import { Container } from './styles';
import closeImg from '../../../assets/close.svg';

export function VehicleImageModal({ isOpen, onRequestClose, vechicleImageUrl }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container>
        <h2>Imagem do Veículo</h2>

        <img src={vechicleImageUrl} alt='Imagem do Veículo' width={340} />
      </Container>
    </Modal>
  )
}