import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Column, Icon, Title } from 'rbx'
import { Link } from 'react-router-dom'

export const QuickAccess = ({ items }) => {
  const none = () => true
  return (
    <Column.Group vcentered multiline breakpoint=''>
      {
        items.map((item, index) => (
          <Column key={index} size=''>
            <Link
              to={item.external ? { pathname: item.to } : item.to}
              target={item.external ? '_blank' : '_self'}
              onClick={item.click ? item.callback : none}
            >
              <Box 
                className='is-flex is-align-items-end p-2' 
                textAlign="centered" 
                style={{ borderRadius:'10px' }}
              >
                <Box
                  className='mb-0 p-1'
                  style={{ backgroundColor:'var(--blue2)', borderRadius:'10px' }}
                >
                  <Icon size='large' style={{ color:'var(--shape)' }}>
                    <FontAwesomeIcon className={item.className} icon={item.icon} size="2x" />
                  </Icon>
                </Box>
                <Title className='ml-3' subtitle size={item.size}>{item.title}</Title>
              </Box>
            </Link>
          </Column>
        ))
      }
    </Column.Group>
  )
}
