import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ContainerForm, IconButton, InfoContainer, InfoContainer2 } from './styles';
import MaskedInput from 'react-text-mask';
import { infractionMask, maskPlate } from '../../../../utils/masks';
import IrregularVehicleService from '../../../../services/irregular-vehicle';
import { closeImg, editImg } from '../../../../utils/images';
import { RegressiveCounter } from '../../../shared/RegressiveCounter';

export function IrregularVehicleDetail({ onDetailClose, detailsData, refreshList }) {
  const [loading, setLoading] = useState(false);
  const [licensePlate, setLicensePlate] = useState('');
  const [editLicensePlate, setEditLicensePlate] = useState(false);
  const [local, setLocal] = useState('');
  const [infractionCode, setInfractionCode] = useState('');
  const [note, setNote] = useState('');

  const { id: irregularId, date: irregularDate, startDate: irregularTime } = detailsData

  useEffect(() => {
    setLicensePlate(detailsData.licensePlate)
    setLocal(`${detailsData.local} ${detailsData.reference ? '-' : ''} ${detailsData.reference ?? ''}`)
    setInfractionCode('')
    setNote('')
  }, [detailsData])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await IrregularVehicleService.confirmIrregularVehicle(irregularId, 
        { licensePlate, local, infractionCode, note }
      )
      toast.success(response.data.message)
      refreshList()
      setTimeout(() => {
        setLoading(false)
        onDetailClose()
      }, 3000);
    } catch (error) {
      toast.error(error.data?.message)
    }
  };

  const toggleEditLicensePlate = () => { setEditLicensePlate(!editLicensePlate) }

  return (
    <ContainerForm onSubmit={handleSubmit} className="animeLeft">
      <header>
        <span></span>
        <h3>Detalhes da Irregularidade</h3>
        <IconButton onClick={onDetailClose} style={{paddingRight:'0px'}}>
          <img src={closeImg} alt="Home" title="Home" />
        </IconButton>
      </header>

      <InfoContainer>
        <div style={{textAlign: 'center'}}>
          <img src={detailsData.imageVehicle} alt='Imagem do Veículo' className='responsive' />
        </div>

        <div>
          <p style={{marginBottom:'0.5rem'}}>
            Data e hora: <span>{irregularDate} {irregularTime}</span>
          </p>
          <InfoContainer2>
            <p>
              <label>Placa</label>
              {
                editLicensePlate ? (
                  <MaskedInput
                    mask={maskPlate}
                    placeholder="ABC1A34"
                    pattern='^[A-Z]{3}\d[0-9A-J]\d{2}$'
                    title='A placa informada está fora do padrão.'
                    guide={false}
                    type="text"
                    value={licensePlate}
                    onChange={(event) => setLicensePlate(event.target.value.toUpperCase())}
                    required
                    style={{marginBottom:'0.5rem'}}
                  />
                ) : (
                  <InfoContainer2>
                    <span style={{fontSize:'1.5rem', padding:'5px'}}>{licensePlate}</span>
                    <IconButton onClick={toggleEditLicensePlate}>
                      <img src={editImg} alt="Home" title="Home" />
                    </IconButton>
                  </InfoContainer2>
                )
              }
            </p>
            <p>
              <label>Enquadramento</label>
              <MaskedInput
                mask={infractionMask}
                placeholder="XXXX-X"
                guide={false}
                type="text"
                value={infractionCode}
                onChange={(event) => setInfractionCode(event.target.value)}
                required
                style={{marginBottom:'0.5rem'}}
              />
            </p>
          </InfoContainer2>

          <label>Local</label>
          <textarea
            placeholder="Local"
            type="text"
            maxLength={250}
            value={local}
            onChange={(event) => setLocal(event.target.value)}
            required
            style={{width:'100%', height:'7.5rem'}}
          />
          <RegressiveCounter size={local.length} maxSize={250} />

          <label>Observação</label>
          <textarea
            placeholder="Observação"
            type="text"
            maxLength={100}
            value={note}
            onChange={(event) => setNote(event.target.value.toUpperCase())}
            style={{width:'100%', height:'6rem'}}
          />
          <RegressiveCounter size={note.length} maxSize={100} />

          {loading ? (
            <button className="secondButton" disabled>
              Confirmando ...
            </button>
          ) : (
            <button className="secondButton">
              Confirmar Irregularidade
            </button>
          )}
        </div>
      </InfoContainer>
    </ContainerForm>
  );
}
