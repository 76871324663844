import { Api } from "./api";

const UserService = {
  cleanStorage: () => {
    sessionStorage.removeItem('app')
    sessionStorage.removeItem('appId')
  },

  findBy: (key, value) => Api.get(`/adm/users?${key}=${value}`, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  reload: async () => {
    const response = await UserService.findBy('id', sessionStorage.getItem('appId'))
    if (response.data.data) {
      UserService.select(response.data.data)
      return true;
    } else {
      return false;
    }
  },

  select: (user) => { 
    sessionStorage.setItem('app', JSON.stringify(user)) 
    sessionStorage.setItem('appId', user.id)
  },

  get: () => JSON.parse(sessionStorage.getItem('app')),

  register: (params) => Api.post("/users", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  resendCode: (params) => Api.post("/resend-code", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  forgotPassword: (params) => Api.post("/forgot-password", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  updateMyAccount: (params) => Api.patch("/users", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  updateOtherAccount: (params) => Api.patch("/adm/update-users", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  activeAccount: (params) => Api.put("/active-account", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  sellCad: (params) => Api.post("/monitors/cad-sale", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  purchaseHistory: (userId) => Api.get(`/adm/historic-payments/${userId}`, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  saleHistory: (userId) => Api.get(`/adm/historic-sales/${userId}`, { headers: { 'Authorization': sessionStorage.getItem('token') } }),
  
  parkingHistory: (userId) => Api.get(`/adm/historic-parkings/${userId}`, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  cadWallet: () => Api.get(`/transactions`, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  lock: (params) => Api.patch("/adm/lock-users", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  unlock: (params) => Api.patch("/adm/unlock-users", params, { headers: { 'Authorization': sessionStorage.getItem('token') } }),

  notifyAccountDeletion: () => Api.post("/notify-account-deletion", {}, { headers: { 'Authorization': sessionStorage.getItem('token') } }),
}

export default UserService
