import { useContext, useEffect, useState, useRef } from 'react'
import { Dropdown, Icon, Button, Title } from 'rbx'
import userIconImage from '../../../assets/user-avatar.png'
import AuthContext from '../../../contexts/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserService from '../../../services/user'
import { toast } from 'react-toastify'
import { faEdit } from '@fortawesome/free-regular-svg-icons'


export const UserDropdown = ({ setRedirectTo, toggleUserModal }) => {
  const { logout } = useContext(AuthContext);
  const [cad, setCad] = useState(0)
  
  const profile2 = localStorage.getItem('profile2')
  const shortName = localStorage.getItem('shortName')

  const dropdown = useRef(null)

  useEffect(()=>{
    fetchCadWallet()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    const dropdownTrigger = document.querySelector('div.dropdown-trigger')
    dropdownTrigger.addEventListener('focusout', desactiveDropdown)
    return () => {
      dropdownTrigger.removeEventListener('focusout', desactiveDropdown)
    }
  },[])

  const desactiveDropdown = () => {
    dropdown.current.classList.remove('is-active')
  }

  const fetchCadWallet = async () => {
    try {
      const response = await UserService.cadWallet()
      setCad(response.data.data.cads)
    } catch (error) {
      if (error.response.status === 401) logout()
      if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <Dropdown ref={dropdown} align="right">
      <Dropdown.Trigger>
        <img className='is-clickable is-rounded' src={userIconImage} alt='user' />
      </Dropdown.Trigger>
      <Dropdown.Menu>
        <Dropdown.Content>
          <div className="dropdown-item is-flex is-justify-content-space-between">
            <div>
              <Title size={5}>{shortName}</Title>
              <Title subtitle size={6}>{profile2}</Title>
            </div>
            <Icon
              className='is-clickable is-white gb_Fc' 
              title='Editar' 
              size='large' 
              onClick={() => {
                toggleUserModal()
                desactiveDropdown()
              }}
              style={{fontSize:'1.3rem'}}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
          </div>
          <Dropdown.Divider />
          <div className="dropdown-item">
            Carteira: {cad} CAD
          </div>
          <Dropdown.Divider />
          <div className='px-4 py-2'>
            <Button color='danger' size='' outlined fullwidth onClick={e => logout()}>
              SAIR
            </Button>
          </div>
        </Dropdown.Content>
      </Dropdown.Menu>
    </Dropdown>
  )
}
