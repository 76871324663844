import { useEffect, useState } from 'react'
import { Column, Icon, Title } from 'rbx'
import { InfoWindow, Marker } from '@react-google-maps/api'
import { useFetch } from '../../../hooks/useFetch'
import { API_URL } from '../../../services/api'
import Loading from '../../../helpers/Loading'
import moment from 'moment'
import pinMonitorOnline from '../../../assets/pin-monitor-online.png'
import pinMonitorOffline from '../../../assets/pin-monitor-offline.png'
import Controls from '../../shared/Controls/Controls'
import { Redirect } from 'react-router-dom'
import { RedirectTo } from '../../../utils/redirectTo'
import { Timer } from '../../shared/Timer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass } from '@fortawesome/free-regular-svg-icons'
import { ZadMap } from '../../shared/ZadMap'

export const MonitorsNow = () => {
  const [time, setTime] = useState(null)
  const [redirectTo, setRedirectTo] = useState(null)
  const [activeMarker, setActiveMarker] = useState(null)
  const [search, setSearch] = useState('')

  const { 
    response: locationMonitorsResponse,
    load: locationMonitorsLoad,
    refresh: locationMonitorsRefresh
  } = useFetch(`${API_URL}/adm/find-location-monitors`)

  const { 
    response: areasResponse,
    load: areaLoad
  } = useFetch(`${API_URL}/areas`)

  const areasMap = areasResponse?.data||[]

  const minutes = 1

  useEffect(() => {
    setTime(moment())

    if (!locationMonitorsLoad) {
      const interval1 = setInterval(() => {
        locationMonitorsRefresh()
        setTime(moment())
      }, minutes * 60 * 1000)

      return () => clearInterval(interval1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // console.log('MonitorsNow', 'Rendered')

  if (redirectTo)
  return <Redirect to={{ pathname: redirectTo }} />

  const filteredMonitors = search.length > 0 
    ? locationMonitorsResponse?.data.filter((monitor) => monitor.name.toUpperCase().includes(search))
    : locationMonitorsResponse?.data || []

  const closeInfoWindow = () => {
    setActiveMarker(null)
  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return
    }
    closeInfoWindow()
    setActiveMarker(marker)
  }

  const searchContainerStyle = {
    position: 'absolute',
    top: 6,
    left: 4,
    zIndex: 4,
    pointerEvents: 'auto',
    overflow: 'hidden',
    whiteSpace: 'normal',
    fontFamily: '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    width: '100%',
    maxWidth: '30rem'
  }

  const timerContainerStyle = {
    background: '#fff',
    fontSize: '1.4rem',
    padding: '0.4rem 0.5rem',
    border: '1px solid hsl(0deg, 0%, 86%)',
    borderRadius: '4px',
    color: 'var(--text-body)',
    maxWidth: '10rem'
  }

  return !areaLoad ? (
    <>
      <div className='search-container' style={searchContainerStyle}>
        <Column.Group gapSize={1} breakpoint='mobile'>
          <Column>
            <Controls.TextFieldWithSearch2 
              name="description"
              placeholder='Pesquisar monitor'
              autoComplete='off'
              onChange={e => {
                closeInfoWindow()
                setSearch(e.target.value.toUpperCase())
              }}
              value={search}
              onClickFirst={()=>setRedirectTo(RedirectTo.dashboard)}
              onClickSecond={() => setSearch('')}
            />
          </Column>
          <Column size={'one-quarter'}>
            <div className='is-flex is-align-items-center' style={timerContainerStyle}>
              <Icon style={{fontSize:'1.5rem', color:'var(--text-title)', paddingLeft:'.2rem'}}>
                <FontAwesomeIcon icon={faHourglass} />
              </Icon>
              <span style={{paddingTop:'.1rem', marginLeft:'1rem'}}>
                <Timer 
                  startDate={time}
                  paidMinutes={minutes} 
                  reverse={true} 
                  format='mm[:]ss'
                />
              </span>
            </div>
          </Column>
        </Column.Group>
      </div>

      <ZadMap
        areasMap={areasMap}
        mapContainerStyle={{ width: '100vw', height: '100vh' }}
        zoom={17}
        onClick={closeInfoWindow}
      >
        {
          locationMonitorsResponse && (
            filteredMonitors.map(location => (
              <Marker
                key={Math.random()}
                title={location.name}
                icon={location.online ? pinMonitorOnline : pinMonitorOffline}
                position={{ lat: Number(location.lat), lng: Number(location.lng) }}
                onClick={() => handleActiveMarker(location.id)}
              >
                {
                  activeMarker === location.id ? (
                    <InfoWindow>
                      <div style={{ background: 'white', border: '1px solid #ccc', padding: 15 }}>
                        <Title size={6}>{location.name}</Title>
                        <Title subtitle size={7}>{moment(location.date).format('DD/MM/YYYY HH:mm:ss')}</Title>
                      </div>
                    </InfoWindow>
                  ) : <></>
                }
              </Marker>
            ))
          )
        }
      </ZadMap>
    </>
  ) : <Loading />
}
