import { useState, useEffect } from 'react';
import { Box, Column } from "rbx";
import { monetaryMask } from '../../commons/masks';

const PurchaseSummary = (props) => {
  const { quantity, discount } = props;
  const [costCADs, setCostCADs] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [payable, setPayable] = useState(0);
  
  useEffect(()=>{
    const calculate = () => {
      const quantityCADs = quantity||0;
      const discountA = (discount/100)||0;
  
      const costCADsA = quantityCADs * +process.env.REACT_APP_CAD_VALUE;
      const discountAmountA = costCADsA * discountA;
      const payableA = costCADsA - discountAmountA;
  
      setCostCADs( costCADsA );
      setDiscountAmount( discountAmountA );
      setPayable( payableA );
    }
    calculate();
  }, [quantity, discount]);


  return (
    <Box className='py-3'>
      Resumo da Venda:
      <hr className='hr my-2' />
      <Column.Group breakpoint="mobile">
        <Column size={8} className="has-text-left">
          + Valor dos CADs:<br/>
          - Valor do Desconto:<br/>
          <hr className="hr my-2"/>
          <strong className='has-text-custom-purple'>= Valor a Pagar:</strong>
        </Column>
        <Column className="has-text-right">
          {monetaryMask(costCADs)}<br/ >
          {monetaryMask(discountAmount)}<br/ >
          <hr className="hr my-2"/>
          <strong className='has-text-custom-purple'>{monetaryMask(payable)}</strong>
        </Column>
      </Column.Group>
    </Box>
  )

}

export default PurchaseSummary;
