import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { RedirectTo } from "../../utils/redirectTo";

export default function DashboardPage() {
  return (
    <Layout>
      <div className='is-flex p-6 is-justify-content-center is-flex-direction-column'>
        <Link className='is-flex is-justify-content-center' to={RedirectTo.dashboard}>
          <FontAwesomeIcon 
            icon={faExclamationTriangle} 
            className='p-5 is-clickable'
            size="5x" 
            style={{ color:'var(--purple)' }}
          />
        </Link>
        <span className='is-flex is-size-3 is-justify-content-center' style={{ color:'var(--text-title)' }}>
          Oops! Página não encontrada!
        </span>
      </div>
    </Layout>
  );
}
