import { useState } from 'react';
import { RenewParkingModal } from '../../Modals/RenewParkingModal';
import Timer from 'react-compound-timer';
import CarImg from '../../../assets/car.svg';
import AddImg from '../../../assets/add.svg';
import { Container, IconButton } from './styles';

export function VehiclesParked({
  vehiclesParkedData: vehicles,
  fetchVehiclesParked,
}) {
  const [isRenewParkingModalOpen, setIsRenewParkingModalOpen] = useState(false);
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState('');

  function handleOpenRenewParkingModal() {
    setIsRenewParkingModalOpen(true);
  }

  function handleCloseRenewParkingModal() {
    setIsRenewParkingModalOpen(false);
    fetchVehiclesParked();
    setTimeout(window.location.reload(), 3000);
  }

  return (
    <Container>
      <RenewParkingModal
        isOpen={isRenewParkingModalOpen}
        onRequestClose={handleCloseRenewParkingModal}
        vehicleLicensePlate={vehicleLicensePlate}
        vehicleParkedData={ vehicles?.find( veh => veh.licensePlate === vehicleLicensePlate ) }
      />

      <header>
        <h3>Veículos estacionados</h3>
      </header>

      {vehicles !== null ? (
        vehicles.map((vehicle) => (
          <div key={vehicle.licensePlate}>
            <img src={CarImg} alt="Carro" />
            <span>
              <strong>{vehicle.licensePlate}</strong>
              <strong className="timer">
                <Timer
                  initialTime={vehicle.timeReaming}
                  direction="backward"
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => window.location.reload(),
                    },
                  ]}
                >
                  {() =>
                    vehicle.timeReaming !== 0 && (
                      <>
                        <Timer.Hours />
                        h:
                        <Timer.Minutes />
                        m:
                        <Timer.Seconds />s
                      </>
                    )
                  }
                </Timer>
              </strong>
            </span>
            <IconButton
              onClick={() => {
                handleOpenRenewParkingModal()
                setVehicleLicensePlate(vehicle.licensePlate)
              }}
            >
              <img src={AddImg} alt="Renovar estacionamento" />
            </IconButton>
          </div>
        ))
      ) : (
        <p>Nenhum veículo estacionado.</p>
      )}
    </Container>
  );
}
