import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Container } from './styles';

export function HistorySells({ historySellsData }) {
  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'date',
      label: 'Data e hora',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'quantity',
      label: 'Cartões',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'payment',
      label: 'Forma de pagamento',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];

  function createData(date, quantity, value, payment) {
    return {
      date,
      quantity,
      value,
      payment,
    };
  }

  const data = [];
  for (var i = 0; i < historySellsData.length; i++) {
    data.push(
      createData(
        historySellsData[i].date,
        historySellsData[i].quantity,
        historySellsData[i].value,
        historySellsData[i].payment,
      ),
    );
  }

  const options = {
    responsive: 'standard',
    draggableColumns: {
      enabled: false,
    },
    selectableRows: 'none',
    rowsPerPage: 2,
    rowsPerPageOptions: [],
    print: 'false',
    filter: 'false',
    download: 'false',
    viewColumns: 'false',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
  };

  return (
    <Container>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title="Histórico de vendas"
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  );
}
