import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import successImg from '../../../assets/success.svg';
import { maskPlate } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import { Container, Result, ImageUpload, InputsGrid, } from './styles';
import { SearchParkingPlace } from '../../shared/SearchParkingPlace';

export function RegisterIrregularVehicleModal({ isOpen, onRequestClose, lastLicensePlate }) {
  const [licensePlate, setLicensePlate] = useState('');
  const [local, setLocal] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [reference, setReference] = useState('');
  const [references, setReferences] = useState([]);
  const [imageVehicle, setImageVehicle] = useState(null);
  const [loading, setLoading] = useState('');
  const [requestSuccess, setRequestSuccess] = useState('');

  useEffect(() => {
    cleanForm()
    setLicensePlate(lastLicensePlate)
  }, [lastLicensePlate]);

  const handleRegisterIrregularVehicle = async (e) => {
    e.preventDefault();

    if (!local) { toast.warn('Informe o local do estacionamento.'); return; }

    if (!latitude) { toast.warn('Latitude: não pode ser nulo.'); return; }

    if (!longitude) { toast.warn('Longitude: não pode ser nulo.'); return; }

    if (references.length>0 && !reference) { toast.warn('Selecione a referência do local.'); return; }

    setLoading(true);

    const licensePlateFormat = licensePlate.replace('-', '');

    const formData = new FormData();
    formData.append('licensePlate', licensePlateFormat);
    formData.append('local', local);
    formData.append('imageVehicle', imageVehicle, imageVehicle?.name);
    if (reference) formData.append('reference', reference)
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);

    const res = await fetch(`${API_URL}/monitors/set-irregular-vehicle`, {
      method: 'POST',
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
      body: formData,
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      cleanForm()
      setRequestSuccess(response.message)
    }

    setLoading(false);
  };

  const cleanForm = () => {
    setLicensePlate('');
    setLocal('')
    setReference('')
    setReferences([])
    setLatitude('')
    setLongitude('')
    setImageVehicle(null)
    setRequestSuccess('')
  }

  if (requestSuccess !== '')
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={onRequestClose}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>

        <Result className="animeLeft">
          <h2>{requestSuccess}</h2>
          <img src={successImg} alt="Sucesso" onClick={onRequestClose} />
        </Result>
      </Modal>
    );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleRegisterIrregularVehicle}>
        <h2>Registrar Veículo Irregular</h2>

        <MaskedInput
          mask={maskPlate}
          placeholder="Placa"
          guide={false}
          type="text"
          value={licensePlate.toUpperCase()}
          onChange={(event) => setLicensePlate(event.target.value)}
          disabled
          required
        />

        <SearchParkingPlace 
          setAddress={setLocal}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setReferences={setReferences}
          local={true}
        />

        <input
          placeholder="Local"
          type="text"
          value={local}
          onChange={(event) => setLocal(event.target.value)}
          required
        />

        <InputsGrid>
          <input
            placeholder="Latitude"
            type="text"
            value={latitude}
            onChange={(event) => setLatitude(event.target.value)}
            disabled
          />

          <input
            placeholder="Longitude"
            type="text"
            value={longitude}
            onChange={(event) => setLongitude(event.target.value)}
            disabled
          />
        </InputsGrid>

        {
          references.length > 0 && (
            <select
              value={reference}
              onChange={(event) => setReference(event.target.value)}
            >
              <>
                <option value="" disabled defaultValue hidden>Selecione uma referência</option>
                {references.map(
                    (reference) => (<option key={reference.id} value={reference.name}>{reference.name}</option>)
                )}
              </>
            </select>
          )
        }

        <ImageUpload
          placeholder="Imagem"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(event) => setImageVehicle(event.target.files[0])}
          required
        />

        {loading ? (
          <button type="submit" disabled>
            Registrando
          </button>
        ) : (
          <button type="submit">Registrar</button>
        )}
      </Container>
    </Modal>
  );
}
