import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../../services/api';
// import Loading from '../../../helpers/Loading';
import MUIDataTable from 'mui-datatables';
import MaskedInput from 'react-text-mask';
import { maskPlate } from '../../../utils/masks';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, ContainerForm, InputGrid } from '../styles';
import moment from 'moment';
import { Redirect, useParams } from 'react-router-dom';
import { TitleButtons } from '../../TitleButtons';
import { RedirectTo } from '../../../utils/redirectTo';
import { Column, Icon, Modal } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faImage, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { VehicleImageModal } from '../../Modals/VehicleImageModal';
import { useFetch } from '../../../hooks/useFetch';
import Select from 'react-select'
import { IrregularVehicleQrCodeModal } from '../../Modals/IrregularVehicleQrCodeModal';

const configModal = { active: false, page: '' }

export function IrregularVehicles() {
  const [irregularVehiclesData, setIrregularVehiclesData] = useState({});
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [licensePlate, setLicensePlate] = useState('');
  const [isVehicleImageModalOpen, setIsVehicleImageModalOpen] = useState(false)
  const [vechicleImageUrl, setVechicleImageUrl] = useState('')
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState('')
  const [modal, setModal] = useState(configModal)

  const { startDateParam, endDateParam } = useParams()
  const [monitor, setMonitor] = useState({})

  const { response: monitorsData } = useFetch(`${API_URL}/adm/monitors`)

  const monitors = monitorsData?.data?.map((m) => ({ value: m.cpf_cnpj, label: m.name }))

  const fetchIrregularVehicles = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await fetch(
      `${API_URL}/adm/irregular-vehicle-history?licensePlate=${licensePlate}&startDate=${startDate}&endDate=${endDate}&monitorID=${monitor?.value}`,
      {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token'),
        },
      },
    );

    const response = await res.json();
    const body = response.data;

    if (response.message === 'Veículo regular.') {
      toast.error('Nenhum veículo localizado com esses parâmetros.');
      setLoading(false);
      return;
    }

    if (body === null) {
      toast.error('Nenhum veículo localizado com esses parâmetros.');
      setLoading(false);
      return;
    }

    setIrregularVehiclesData(body);
    setLoading(false);
  };

  if (redirectTo)
    return <Redirect to={{ pathname: redirectTo }} />

  if (startDateParam && endDateParam) {
    setStartDate(startDateParam)
    setEndDate(endDateParam)
    fetchIrregularVehicles()
  }

  const toggleVehicleImageModal = () => {
    if (isVehicleImageModalOpen) setVechicleImageUrl('')
    setIsVehicleImageModalOpen(!isVehicleImageModalOpen);
  }

  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'date',
      label: 'Data',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'startDate',
      label: 'Inicio',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'endDate',
      label: 'Fim',
      options: { filter: false, sort: true, display: true },
    },
    {
      name: 'local',
      label: 'Local do registro',
      options: { filter: false, sort: false, display: true },
    },
    {
      name: 'licensePlate',
      label: 'Placa',
      options: { filter: true, sort: true, display: true },
    },
    {
      name: 'imageVehicle',
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Icon
              className='is-clickable mx-3'
              style={{fontSize:'1.2rem'}}
              onClick={() => {
                toggleVehicleImageModal();
                setVechicleImageUrl(value);
              }}
              title="Imagem do veículo"
            >
              <FontAwesomeIcon icon={faImage} />
            </Icon>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'QRCode',
      options: { 
        filter: false, 
        sort: false, 
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Icon
              className='is-clickable mx-3'
              style={{fontSize:'1.2rem'}}
              onClick={() => setPageQrCode(value)}
              title={tableMeta.rowData[7] ? 'Pago em: ' + moment(tableMeta.rowData[7]).format('DD/MM/YYYY HH:mm:ss') : "Código QR para pagamento"}
            >
              {
                tableMeta.rowData[7]
                  ? <FontAwesomeIcon icon={faDollarSign} color='green' />
                  : <FontAwesomeIcon icon={faQrcode} />
              }
            </Icon>
          );
        }
      },
    },
    {
      name: 'paidAt',
      label: 'Data PG',
      options: { filter: true, sort: true, display: false },
    }
  ];

  const options = {
    responsive: 'standard',
    draggableColumns: {
      enabled: true,
    },
    selectableRows: 'none',
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: 'false',
    filter: 'false',
    download: 'true',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'Veículos Irregulares.csv',
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
  };

  const setPageQrCode = (irregularityId) => {
    setModal({
      active: true,
      page: <IrregularVehicleQrCodeModal closeModal={closeModal} irregularityId={irregularityId} />
    })
  }

  const closeModal = () => setModal(configModal)

  // if (loading) return <Loading />;
  return irregularVehiclesData.length === undefined ? (
    <ContainerForm onSubmit={fetchIrregularVehicles} className="animeLeft">
      <TitleButtons
        handleBtnBackClick={ () => setRedirectTo(RedirectTo.dashboard) }
      >
        <h3>Veículos Irregulares</h3>
      </TitleButtons>

      <strong>Parâmetros de pesquisa opcionais</strong>

      <InputGrid>
        <Column.Group className='mb-0'>
          <Column className='pb-0'>
            <label for="startDate">Data início</label>
            <input
              placeholder="Data de início"
              type="date"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
            />
          </Column>
          <Column className='pb-0'>
            <label for="endDate">Data fim</label>
            <input
              placeholder="Data de fim"
              type="date"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
            />
          </Column>
        </Column.Group>

        <div>
          <label for="userType">Monitor</label>
          <Select
            className=""
            classNamePrefix="select"
            defaultValue={monitors?.filter(m => m.value === monitor?.value)}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="monitor"
            options={monitors}
            onChange={(e) => setMonitor(e)}
          />
        </div>

        <div>
          <label for="endDate">Placa do veículo</label>
          <MaskedInput
            mask={maskPlate}
            placeholder="HXH2022"
            guide={false}
            type="text"
            value={licensePlate.toUpperCase()}
            onChange={(event) => setLicensePlate(event.target.value)}
          />
        </div>

        {loading ? (
          <button type="submit" disabled>
            Carregando...
          </button>
        ) : (
          <button type="submit">Visualizar dados</button>
        )}
      </InputGrid>
    </ContainerForm>
  ) : (
    <>
      <Container className="animeLeft">
        <VehicleImageModal 
          isOpen={isVehicleImageModalOpen}
          onRequestClose={toggleVehicleImageModal}
          vechicleImageUrl={vechicleImageUrl}
        />

        <TitleButtons 
          handleBtnBackClick={ () => setIrregularVehiclesData({}) }
        >
          <h3>Veículos Irregulares</h3>
        </TitleButtons>

        <strong className='mt-1'>{`Periodo da irregularidade: ${moment(startDate).format('DD/MM/YYYY')} a ${moment(endDate).format('DD/MM/YYYY')}`}</strong>
        {
          monitor.value && (<strong className='mt-1'>{`Monitor: ${monitor.value} - ${monitor.label}`}</strong>)
        }
        {
          licensePlate && <strong className='mt-1'>{`Placa: ${licensePlate}`}</strong>
        }

        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            title=''
            data={irregularVehiclesData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Container>

      <Modal closeOnBlur={true} closeOnEsc={true} active={modal.active} onClose={closeModal}>
        <Modal.Background onClick={closeModal} />
        <Modal.Content>
          { modal.page }
        </Modal.Content>
      </Modal>
    </>
  );
}
