import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import { API_URL } from '../../../services/api';
import { Container, InfoContainer } from './styles';
import Loading from '../../../helpers/Loading';

export function DetailsHistoryUseModal({
  isOpen,
  onRequestClose,
  detailsData,
}) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const emailUser = localStorage.getItem('email');

  const handleProof = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = { email, authenticateCode: detailsData.authenticateCode };

    const res = await fetch(`${API_URL}/proofs/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
    }

    setLoading(false);
    setEmail('');
  };

  if (Object.keys(detailsData).length === 0)
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Loading />
      </Modal>
    );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleProof}>
        <h2>Detalhes do estacionamento</h2>

        <p>
          Local do estacionamento
          <br />
          <span>{detailsData.addressName}</span>
        </p>

        <InfoContainer>
          <p>
            Data e hora
            <br />
            <span>{detailsData.startDate}</span>
          </p>
          <p>
            Placa do veículo
            <br />
            <span>{detailsData.licensePlate}</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Quantidade de Cartões
            <br />
            <span>{detailsData.quantity}</span>
          </p>
          <p>
            Tempo estacionado
            <br />
            <span>{detailsData.time / 60} hora(s)</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Valor em reais
            <br />
            <span>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(detailsData.value)}
            </span>
          </p>
          <p>
            Código de autenticação
            <br />
            <span>{detailsData.authenticateCode}</span>
          </p>
        </InfoContainer>

        <h3>Informar e-mail para envio do comprovante:</h3>

        <input
          placeholder="E-mail do cliente"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />

        {loading ? (
          <button type="submit" disabled>
            Enviando
          </button>
        ) : (
          <button type="submit">
            Enviar comprovante para o e-mail informado
          </button>
        )}

        <h4>ou</h4>

        {loading ? (
          <button className="secondButton" disabled>
            Enviando
          </button>
        ) : (
          <button
            className="secondButton"
            onClick={() => {
              setEmail(emailUser);
            }}
          >
            Enviar para meu e-mail
          </button>
        )}
      </Container>
    </Modal>
  );
}
