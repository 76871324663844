import { useState } from 'react';
import { DetailsHistoryUseModal } from '../../Modals/DetailsHistoryUseModal';
import MUIDataTable from 'mui-datatables';
import InfoImg from '../../../assets/info.svg';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, IconButton } from './styles';
import { API_URL } from '../../../services/api';

export function HistoryUse({ historyUseData }) {
  const [isDetailsHistoryUseModalOpen, setIsDetailsHistoryUseModalOpen] =
    useState(false);
  const [detailsData, setDetailsData] = useState({});

  function handleOpenDetailsHistoryUseModal() {
    setIsDetailsHistoryUseModalOpen(true);
  }

  function handleCloseDetailsHistoryUseModal() {
    setIsDetailsHistoryUseModalOpen(false);
    setDetailsData({});
  }

  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'startDate',
      label: 'Data e hora',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'addressName',
      label: 'Local do estacionamento',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'authenticateCode',
      label: 'Código de autenticação',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'Detalhes',
      label: 'Detalhes',
      options: {
        filter: false,
        sort: false,
        display: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              onClick={async () => {
                handleOpenDetailsHistoryUseModal(tableMeta.rowData[2]);

                const res = await fetch(
                  `${API_URL}/historic-parkings/${tableMeta.rowData[2]}`,
                  {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: window.localStorage.getItem('token'),
                    },
                  },
                );

                const response = await res.json();

                setDetailsData(response.data);
              }}
            >
              <img src={InfoImg} alt="Mais informações" />
            </IconButton>
          );
        },
      },
    },
  ];

  function createData(startDate, addressName, authenticateCode) {
    return {
      startDate,
      addressName,
      authenticateCode,
    };
  }

  const data = [];
  for (var i = 0; i < historyUseData.length; i++) {
    const fullAdress = historyUseData[i].addressName;

    data.push(
      createData(
        historyUseData[i].startDate,
        fullAdress.toUpperCase(),
        historyUseData[i].authenticateCode,
      ),
    );
  }

  const options = {
    responsive: 'standard',
    draggableColumns: {
      enabled: false,
    },
    selectableRows: 'none',
    rowsPerPage: 2,
    rowsPerPageOptions: [],
    print: 'false',
    filter: 'false',
    download: 'false',
    viewColumns: 'false',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
  };

  return (
    <Container>
      <DetailsHistoryUseModal
        isOpen={isDetailsHistoryUseModalOpen}
        onRequestClose={handleCloseDetailsHistoryUseModal}
        detailsData={detailsData}
      />

      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title="Histórico de estacionamentos"
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  );
}
